import React from "react"
import {IonIcon, IonItem} from "@ionic/react"
import {logInOutline} from "ionicons/icons"
import {DriverInfo} from "../api/model/Drivers";
import {ApiService} from "../api/ApiService";
import '../theme/dmCard.css';

const DriverCard = (props: { model: DriverInfo }) => {
    return (
        <IonItem lines={'none'} color={'light'} className="dm-card w-100">
            <div className="dm-card-content">
                <p className="dm-card-header">{props.model.driverCode}</p>
                {props.model.name && <span className="dm-card-flavor">{props.model.name}</span>}
                <p className="dm-card-sub">Phone #: {props.model.phone || '-'}</p>
                <p className="dm-card-sub">Unit: {!!props.model.unit ? props.model.unit : <span className="color-danger">driver has no unit assigned</span>}</p>
            </div>
            <button className="app-btn app-btn-primary" disabled={!props.model.unit} onClick={() => ApiService.loginAsDriver("DriverCard", {driverCode: props.model.driverCode})}>
                <IonIcon className="pe-2" icon={logInOutline}></IonIcon>
                Login as driver
            </button>
        </IonItem>
    )
}

export default DriverCard
