import React, {useEffect, useState} from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import {chevronForward} from 'ionicons/icons';
import newIcon from "../imgs/new-trainee.png"
import {Link} from "react-router-dom";
import {ApiService} from "../api/ApiService";
import {Trainee} from "../api/model/Trainee";

const TraineeProfile: React.FC = () => {
  const [noTrainees, setNoTrainees] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [releasedTrainees, setReleasedTrainees] = useState<Trainee[]>([]);
  const [unreleasedTrainees, setUnreleasedTrainees] = useState<Trainee[]>([]);
  const [isTrainee, setIsTrainee] = useState(false);
  const [trainee, setTrainee] = useState<Trainee>({
    currentCourseDay: 0,
    traineeCode: '',
    traineeName: '',
    currentCourseId: 0,
    dateCreated: '',
    dmCode: '',
    passed: 0,
    trainerCode: '',
    trainerName: '',
    released: 0,
    seatedDate: ''
  });

  const username = localStorage.getItem("username")!;

  useEffect(() => {
    setIsTrainee(localStorage.getItem('type') === 'trainee');
    ApiService.getTrainees("traineeProfile", {}).then(it => {
      setNoTrainees(it.length === 0);
      setReleasedTrainees(it.filter(trainee => trainee.released !== null));
      setUnreleasedTrainees(it.filter(trainee => trainee.released === null));
    });
  }, []);

  function getTraineeProfile(traineeCode:string) {
    ApiService.getTraineeProfile("traineeProfile", traineeCode).then(it => {
      setTrainee(it);
      setShowModal(true);
    });
  }

  function uniqueKey(unique: string) {
    const key = unique;
    return key;
  }

  return (
    <IonPage>
      <IonHeader >
        <IonToolbar color="primary">
        <IonButtons slot="start">
            <IonBackButton defaultHref="/Home" />
          </IonButtons>
          <IonTitle>Trainee Profile(s)</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
        <IonModal isOpen={showModal} cssClass='my-custom-class'>
 
        <div>
            <IonItem lines="none" color="light">
              <div className='item-container'>
                <p>Trainee: {trainee.traineeCode}</p>
                <p>Student: {trainee.traineeName}</p>
                <p>DM Code: {trainee.dmCode}</p>
                <p>Current Course Day: {trainee.currentCourseDay}</p>
                <p>Released: {trainee.released ? trainee.released.toString().replace('T', ' ').slice(0,-14) : "Not released"}</p>
                <p>Passed: {trainee.passed ? "Yes" : "No"}</p>
              </div> 
            </IonItem>
            {/* <IonItem lines="none" color="light">
              <div className='item-container'>
                <p>Student: {trainee.traineeName}</p>
              </div> 
            </IonItem>
            <IonItem lines="none" color="light">
              <IonLabel>DM Code</IonLabel>
              <IonNote slot="end" color="black">{trainee.dmCode}</IonNote>
            </IonItem>
            <IonItem lines="none" color="light">
              <IonLabel>Current Course Day</IonLabel>
              <IonNote slot="end" color="black">{trainee.currentCourseDay}</IonNote>
            </IonItem>
            <IonItem lines="none" color="light">
              <IonLabel>Released</IonLabel>
              <IonNote slot="end" color="black">{trainee.released ? trainee.released.toString().replace('T', ' ').slice(0,-14) : "Not released"}</IonNote>
            </IonItem>
            <IonItem lines="none" color="light">
              <IonLabel>Passed</IonLabel>
              <IonNote slot="end" color="black">{trainee.passed ? "Yes" : "No"}</IonNote>
            </IonItem> */}
            <IonItem lines="none" color="light">
              <div className='item-container'>
                <button className="button"  onClick={ function() { setShowModal(false)}}>Close</button>
              </div>
            </IonItem>
            </div>
        </IonModal>
      { isTrainee ?
        <IonList className="mb-20">
          <IonItem key={username} onClick={() => {getTraineeProfile(username)}}>
            <IonLabel>
              <h2>Trainee: {username}</h2>
              <h6>Course Day: <IonNote slot="end">{trainee.currentCourseDay}</IonNote></h6> 
            </IonLabel>
          </IonItem>
          <IonButton id='western-button' onClick={()=>{getTraineeProfile(username)}}>Calculate Progress</IonButton>
        </IonList>
        
      : !noTrainees ?
      <IonList className="mb-20" style={{padding: "0px"}}>
        <IonItem lines="none" color='light'>
          <div className='item-container'>
            <p className="title">Select Trainee Profile</p>
            <p className='subtitle'>Found {unreleasedTrainees.length} trainees.</p>
          </div>
        </IonItem>
        <IonItem lines="none" color='light'>
          <Link to="/newtrainee" style={{width: "100vw"}} className="western-button-link">
              <div className="green">
                  <img src={newIcon} alt="orientation" className="link-icon"/><p>New Trainee</p>
              </div>
          </Link>
        </IonItem>
        <IonItem lines="none" color='light'>
          <div className='item-container'>
            <p className="subtitle">Unreleased Trainees</p>
            <p className='subtitle'>Found {unreleasedTrainees.length} trainees.</p>
          </div>
        </IonItem>
      {unreleasedTrainees.map(function(trainee) {
         return <IonItem key={uniqueKey(trainee.traineeCode)} onClick={() => {getTraineeProfile(trainee.traineeCode)}} lines="none" color="light">
          <div className='item-container' style={{ backgroundColor: "#ffffff", padding: "4px", borderRadius: "10px"}}>
            <div className="space-between">
              <div style={{ width: "80%"}}>
                <p style={{ fontSize:"medium", margin: "4px"}}>{trainee.traineeCode}</p>
                <p style={{ fontSize: "small", margin: "4px"}}>Status: {trainee.released !== null ? "Released on: " + trainee.released : "In Training"}</p>
              </div>
              <IonIcon icon={chevronForward} style={{ fontSize: "large"}}></IonIcon>
            </div>
          </div>
        </IonItem>
      })}
      <IonItem lines="none" color='light'>
          <div className='item-container'>
            <p className="subtitle">Released Trainees</p>
            <p className='subtitle'>Found {releasedTrainees.length} trainees.</p>
          </div>
        </IonItem>
      {releasedTrainees.map(function(trainee) {
         return <IonItem key={uniqueKey(trainee.traineeCode)} onClick={() => {getTraineeProfile(trainee.traineeCode)}} lines="none" color="light">
          <div className='item-container' style={{ backgroundColor: "#ffffff", padding: "4px", borderRadius: "10px"}}>
            <div className="space-between">
              <div style={{ width: "80%"}}>
                <p style={{ fontSize:"medium", margin: "4px"}}>{trainee.traineeCode}</p>
                <p style={{ fontSize: "small", margin: "4px"}}>Status: {trainee.released !== null ? "Released on: " + trainee.released.toString().replace('T', ' ').slice(0,-14).substr(5) + "-" +  trainee.released.toString().substr(0, 4) : "In Training"}</p>
              </div>
              <IonIcon icon={chevronForward} style={{ fontSize: "large"}}></IonIcon>
            </div>
          </div>

        </IonItem>
      })}

    </IonList>
      : 
      <IonCard>
      <IonCardHeader>
          <IonCardSubtitle>Attention!</IonCardSubtitle>
          <IonCardTitle>You Have No Trainees</IonCardTitle>
      </IonCardHeader>
          <IonCardContent>
            <p style={{fontSize: "17px", marginBottom: '20px'}}>Please click <span style={{color: "#296a27"}}><strong> *New Trainee</strong></span> to claim a trainee for instruction.</p>
            <IonButton id="western-button" expand="block" href="/Training/newtrainee"><div id="btn-text">New Trainee</div></IonButton>
          </IonCardContent>  
      </IonCard> }
      </IonContent>
    </IonPage>
  );
};

export default TraineeProfile;
