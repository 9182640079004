import React, { useState, useEffect } from "react"
import { IonButton, IonItem, IonLabel} from "@ionic/react";
import AnimateHeight from "react-animate-height";
import { Markup } from "interweave";
// import { coreValueOfTheDay } from "../Util";

function NotificationCard(props: any) {

    const [height1, setHeight1] = useState(0);

    useEffect(() => {
        // console.log("props", props);
    }, [])
        
    const toggle = () => {
        console.log("toggle");
        if (height1 === 0) {setHeight1(1);}
        else {setHeight1(0)}
    }

    const abridge = (post: string) => {
        return post.slice(0, 75) + "..."
    }

    return(
        <div className={props.notificationElement.read !== 1 ? "notification-card" : "notification-card-read"} onClick={e => {toggle(); props.readStatus(props.notificationElement.id)}}>
            <div className="post-title" style={{width: "100%"}}>
                <h6>{props.notificationElement.sent.replace('T', ' ').slice(0,-14).substr(5) + "-" + props.notificationElement.sent.substr(0, 4)}</h6>
            </div>
            <p className="post-body">{ height1 !== 1 ? abridge(props.notificationElement.message) : props.notificationElement.message }</p> 
        </div>
    
  )
}

export default NotificationCard;