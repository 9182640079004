import React, { useState } from "react"
import { IonButton, IonItem, IonLabel} from "@ionic/react";
import AnimateHeight from "react-animate-height";
import { Markup } from "interweave";
// import { coreValueOfTheDay } from "../Util";

function HelpDeskEntry(props: any) {
  const [toggle, setToggle] = useState(false)


    return(
      <div className="question-box" key={props.element.priority} onClick={() => setToggle(!toggle)}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}><p style={{ width: "80%"}}>{props.element.question}</p><p>{toggle ?  <div className='letter-icon' style={{ backgroundColor: "#FF8575"}}><p>-</p></div>: <div className='letter-icon' style={{ backgroundColor: "#9975FF"}}><p>+</p></div>}</p></div>
        <p style={{ fontSize: "small"}} id={toggle ? "expanded" : "collapsed"}><Markup content={props.element.answer} /></p>
      </div>
  )
}

export default HelpDeskEntry;