import React, {useEffect, useState} from 'react';
import {IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonList, IonModal, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import {close} from 'ionicons/icons';
import {config} from '../Constants'
import documentIcon from "../imgs/document.png"
import {ApiService} from "../api/ApiService";
import {DocumentInfo} from "../api/model/DocumentsList";
import {VideoInfo} from "../api/model/VideosList";

const SafetyDocs : React.FC = () => {

  const [showModalDoc, setShowModalDoc] = useState(false);
  const [showModalVideo, setShowModalVideo] = useState(false)
  const [selectedDoc, setSelectedDoc] = useState<DocumentInfo>();
  const [docs, setDocList] = useState<DocumentInfo[]>([]);
  const [vidLink, setVidLink] = useState("");
  const [vidIds, setVidIds] = useState<VideoInfo[]>();

  useEffect(() => {
    ApiService.getDocsList("safety", {type: 'safety'}).then(it => setDocList(it));
    ApiService.getVideosList("safety", {type: 'safety'}).then(it => setVidIds(it));
  }, []);

  return (
    <IonPage >
      <IonHeader >
        <IonToolbar color="primary">
        <IonButtons slot="start">
            <IonBackButton defaultHref="/Home"  text=""/>
          </IonButtons>
          <IonTitle>Safety</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent  fullscreen>

        <IonModal isOpen={showModalDoc} cssClass='my-custom-class'>

        <IonHeader >
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonButton onClick={ function() { setShowModalDoc(false); }}>
                <IonIcon slot="icon-only" icon={close} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen style={{'--color': 'black'}}>
            <div style={{ height: '100vh'}}>
              <iframe src={'https://westernexpressmobile.com/docs/'+selectedDoc?.type+'_'+selectedDoc?.id+'.'+selectedDoc?.extension+"#toolbar=0"}  style={{ height: '100%', width: '100%'}} allowFullScreen/>
            </div>
          </IonContent>
        </IonModal>
        <IonModal isOpen={showModalVideo} cssClass='my-custom-class'>
        <IonHeader >
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonButton onClick={ function() { setShowModalVideo(false); setVidLink("")}}>
                <IonIcon slot="icon-only" icon={close} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
          <IonContent>
          <iframe id="video-modal" title="safety video" width="100%" height="345" src={vidLink}>
                </iframe>
          </IonContent>

        </IonModal>
        <div className='main-30-70'>
          <div>
       <IonList style={{ padding: '8px'}}>
       <h1 style={{textAlign: 'center', color: 'black'}}>Documents</h1>
       {docs.map(function(doc, idx){
         let id = doc.id
         let title = doc.title
          return config.isPlatformSci ? 
            <IonItem key={id} onClick={() => {setSelectedDoc(doc); setShowModalDoc(true)}} lines="none">
              <div className='item-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <p>{title}</p>
                <img src={documentIcon} className="link-icon" alt="doc"/>
              </div>
              {/* <IonLabel text-wrap>{title}</IonLabel>
              <IonIcon slot="end" icon={chevronForward}></IonIcon> */}
            </IonItem> : 
            <a href={'https://westernexpressmobile.com/docs/'+selectedDoc?.type+'_'+selectedDoc?.id+'.'+selectedDoc?.extension+"#toolbar=0"} target="_blank" style={{textDecoration: "none"}}>
              <IonItem key={id} onClick={() => {setSelectedDoc(doc);}} lines="none" style={{ margin: "8px 0", borderRadius: "10px"}} color="white">
              <div className='item-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor:"#ffffff"}}>
                <p>{title}</p>
                <img src={documentIcon} className="link-icon" alt="doc"/>
              </div>
                {/* <IonLabel text-wrap>{title}</IonLabel>
                <IonIcon slot="end" icon={chevronForward}></IonIcon> */}
              </IonItem>
            </a>
        })} 
        </IonList></div>
        <div>
       <h1 style={{textAlign: 'center', color: 'black'}}>Videos</h1>
       <div className='video-gal'>{vidIds?.map(function(vidIds, idx){
         if (vidIds.active === 1) {
          console.log(vidIds)
         let src = "https://img.youtube.com/vi/" + vidIds.youtube + "/0.jpg"
         let vidLink = 'https://www.youtube.com/embed/' + vidIds.youtube
            return (
               <div className='video-box' key={idx} onClick={ function() { setShowModalVideo(true); setVidLink(vidLink)}}>
       
                {/* <img alt="youtube video thumbnail" src={src}/> */}
                <iframe title="safety video" height="200" src={vidLink}>
                </iframe>
                <p className='title'>{vidIds.title}</p>
            
              {/* <IonLabel text-wrap>{vidIds.title}</IonLabel> */}
              </div>)
    
         }
        })}</div></div>
        </div>
      
      
      </IonContent>
    </IonPage>
  );
};

export default SafetyDocs;

