import React, {useEffect, useState} from "react";
import {IonItem} from "@ionic/react";

import historyIcon from "../imgs/clock-history.png"
import historyActiveIcon from "../imgs/clock-history-active.png"
import ReportDetail from "./ReportDetail";
import {ApiService} from "../api/ApiService";
import {TrainingReport} from "../api/model/TrainingReports";

export interface Report {
    trainerCode: string,
    roadTest: any,
    courseId: number,
    courseDay: number,
    backingScore: number,
    turningScore: number,
    roadHabitsScore: number,
    cityScore: number,
    highwayScore: number,
    tabletScore: number,
    hosScore: number,
    defensiveScore: number,
    overallScore: number,
    dmCode: string,
}

export interface ReportReference {
date: string,
trainerCode: string
}

const ReportHistory = (props: any) => {
    const [showHistory, setShowHistory] = useState(false)
    const [report, setReport] = useState<Report>({
        trainerCode: '',
        roadTest: '',
        courseId: 0,
        courseDay: 0,
        backingScore: 0,
        turningScore: 0,
        roadHabitsScore: 0,
        cityScore: 0,
        highwayScore: 0,
        tabletScore: 0,
        hosScore: 0,
        defensiveScore: 0,
        overallScore: 0,
        dmCode: ''
      })
      const [reports, setReports] = useState<ReportReference[]>([]);
      const [hasReports, setHasReports] = useState(false);

    useEffect(() => {
        ApiService.getTrainingReports2("reportHistory", {traineeCode: props.trainee.traineeCode}, true).then(it => {
            parseReports(it.reports);
            setHasReports(true);
        });
    }, []);

      function parseReports(reportsArray: TrainingReport[]) {
        let tempArray: any[] = []
        reportsArray.forEach(element => {
          let obj: ReportReference = {date: '', trainerCode: ''};
          obj.date = element.date;
          obj.trainerCode = element.trainerCode
          tempArray.push(obj);
        });
        setReports(tempArray);

      }
    return (
        <IonItem lines="none" color="light">
            <div className='item-container' style={{ overflow: "auto"}}>
                {showHistory ? <div className="report-history" >
                    <div style={{ textAlign: "center", position: "relative"}} onClick={() => setShowHistory(!showHistory)}><img src={historyActiveIcon} style={{ height: "2.5vh", position: "absolute", left: "8px", top: "8px"}} />
                    <p className="subtitle">Show Less</p></div>
                    <IonItem lines="none" color="white">
                            <div className="item-container">
                                <p style={{ margin: 0}}>Report History</p>
                            </div>
                        </IonItem>
                    {reports.map(function(dayreport) {
                        console.log(dayreport)
                        return <ReportDetail trainee={props.trainee.traineeCode} date={dayreport.date}/>

                    })}
                     <IonItem lines="none" color="white">
                            <div className="item-container">
                                <p className="subtile">Showing {reports.length} result(s).</p>
                            </div>
                        </IonItem>
                </div> :
                  <div style={{display: "flex", alignItems: "center"}} onClick={() => setShowHistory(!showHistory)}><p className="subtitle" style={{  margin: 0 }}>See History</p><img src={historyIcon} style={{ height: "2.5vh"}}/></div>
                }
            </div>
        </IonItem>
    )
}

export default ReportHistory
