import { useState, useEffect } from 'react';
import { IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonModal, IonButtons, IonBackButton, IonCardContent, IonTextarea, IonItemGroup, IonItemDivider, IonItem, IonLabel, IonSearchbar, IonSelect, IonSelectOption, IonToast, IonIcon } from '@ionic/react';
import { base64FromPath } from '@ionic/react-hooks/filesystem';
import {config} from '../Constants';
import CryptoJS from 'crypto-js';
import md5 from 'crypto-js/md5';
import { camera, cameraOutline, trashOutline, arrowDown, arrowUp } from 'ionicons/icons';
//Google Analytics:
import {getAnalytics, logEvent} from 'firebase/analytics';
import { initializeApp } from "firebase/app";
import AnimateHeight from 'react-animate-height';
import {sendErrorMsg} from '../components/DiscordHookError';
import { Camera } from '@capacitor/camera';
import React from 'react';

// Example images //
import trailerFront from "../imgs/tarp_photo/trailerFront.jpg";
import trailerRear from "../imgs/tarp_photo/trailerRear.jpg";
import trailerSide from "../imgs/tarp_photo/trailerSide.jpg";
import { ApiService } from '../api/ApiService';
import { toastStore } from '../components/AppToast';

const timages: any[] = [];

interface Photo {
  filepath: string;
  webviewPath?: string;
  data: any;
}

var c = document.getElementById("canv");
// var $ = c!.getContext("2d");
let hashArray: any = [];

const PhotoUpload: React.FC<{moduleHistory: string}> = ({ moduleHistory }) => {

  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showErrorModal2, setShowErrorModal2] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successPhoto, setSuccess] = useState(false);
  const [toast, setToast] = useState<string>("")
  const [photoMsg, setPhotoMsg] = useState<string>("Take photo");
  const [images, setImages] = useState<Photo[]>([]);
  const [userLat, setUserLat] = useState<number>(36.1627);
  const [userLong, setUserLong] = useState<number>(-86.7816);
  const [loadNumber, setLoadNumber] = useState<string>("");
  const [dispatchNumber, setDispatchNumber] = useState<string>("");
  const [unitNumber, setUnitNumber] = useState("");
  const [dmEmail, setDmEmail] = useState<string>("");
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [trailerNumber, setTrailerNumber] = useState("");
  const [dmCode, setDmCode] = useState("");
  const [division, setDivision] = useState("");
  const [height1, setHeight1] = useState(false);
  const [height2, setHeight2] = useState(false);
  const [height3, setHeight3] = useState(false);


  useEffect(() => {
    // get unit number from userProfile
    var data = new FormData()
    data.append("payload", JSON.stringify({ cmds: [{cmd: "driverProfile", args: {driverCode: localStorage.getItem('username')?.toUpperCase(), loginToken: localStorage.getItem('token')}}] }))
    const requestOptions = {
      method: 'POST',
      headers: {},
      body: data
    };
    //console.log(requestOptions)
    fetch(config.url.BASE_URL, requestOptions)
      .then(response => response.json())
      .then(json => {
        if (json.results[0].errcode === 'NOTLOGGEDIN') {localStorage.removeItem('token')} 
        else if (json.results[0].errmsg) {
          
          console.error("Error: ", json.results[0].errmsg);
        }
          else {
            setDmCode(json.results[0].dmCode)
            setDmEmail(json.results[0].dmEmail)
            setTrailerNumber(json.results[0].trailer)
            setUnitNumber(json.results[0].unit)
            getLoadNumber(json.results[0].unit)
            setDivision(json.results[0].division)
          }
      })
          // get load number from loadCurrent
  }, [])

  enum CameraResultType {
    Uri = 'uri',
    Base64 = 'base64',
    DataUrl = 'dataUrl'
  } 
  
  function getLoadNumber(unitNumber: string) {
    var data = new FormData()
    data.append("payload", JSON.stringify({ cmds: [{cmd: "loadDetails", args: {unit: unitNumber, username: localStorage.getItem('username')?.toUpperCase(), loginToken: localStorage.getItem('token')}}] }))
    const requestOptions = {
      method: 'POST',
      headers: {},
      body: data
    };
    fetch(config.url.BASE_URL, requestOptions)
    .then(response => response.json())
    .then(json => {if (json.results[0].errcode === 'NOTLOGGEDIN') {localStorage.removeItem('token')} else if (json.results[0].errcode === "SYSERR") {sendErrorMsg("SYSERR: loadDetails - PhotoUpload")} else if (json.results[0].load) { setLoadNumber(json.results[0].load.loadNumber); setDispatchNumber(json.results[0].load.dispatchNumber)} })
  }

  const b64toBlob = (base64:any) => 
  fetch(base64).then(res => res.blob())

  function sendDmMessage() {
    const toList = "kshinkle@westernexp.com";
    const emailBody = 'Driver ' + dmCode + " has submitted a tarp photo. To view this photo, go to: http://software.westernexp.com/admin-console/tarppay and enter the driver code." ;
    var data = new FormData()
    data.append("payload", JSON.stringify({ cmds: [{cmd: 'genericEmail', args: {to: toList, subject: 'Tarp Photo Submitted', body: emailBody, loginToken: localStorage.getItem('token')}}]}))
    const requestOptions = {
      method: 'POST',
      headers: {},
      body: data
    };
    fetch(config.url.BASE_URL, requestOptions)
    .then(response => response.json())
    .then(json => {if(json.results[0].errmsg){setToast((json.results[0].errcode + ": " + json.results[0].errmsg)); sendErrorMsg("SYSERR: genericEmail - PhotoUpload"); setShowToast(true)}else{redirectPage();}})
  }
  
  async function submitReport() {
    setSubmitDisabled(true);
    if (images.length >= 3) {
      await getUserLongLat();
      setLoading(true);
      let ln = loadNumber;
      if (!ln) {console.log('returned'); setToast("No active load found"); setShowToast(true); setShowLoadingModal(false); return;}
      // Submit record
      var data = new FormData()
      data.append("payload", JSON.stringify({ cmds: [{cmd: "genericReportSubmission", args: {driverCode: localStorage.getItem('username')?.toUpperCase(), loginToken: localStorage.getItem('token'), load: ln, trailer: trailerNumber, unit: unitNumber, comment: 'Empty'}}] }))
      const requestOptions = {
        method: 'POST',
        headers: {},
        body: data
      };
      fetch(config.url.BASE_URL, requestOptions)
      .then(response => response.json())
      .then(async json => {if (json.results[0].errcode === "SYSERR") {sendErrorMsg("SYSERR: genericReportSubmission - PhotoUpload")} else if (json.results[0].load) { setLoadNumber(json.results[0].load.loadNumber)}{let refId = json.results[0].genericReportId; submitPhotos(refId, ln);}})
    } else {
        setToast("Please ensure you have the required number of photos for a submission (3)")
        setShowToast(true);
        setSubmitDisabled(false);
        console.log("showToast", showToast);
      }
    }

    async function submitPhotos(refId: any, ln: any) {
      // Submit pictures --
      var data = new FormData()
      data.append("payload", JSON.stringify({ cmds: [{cmd: "photoSubmission", args: {driverCode: localStorage.getItem('username')?.toUpperCase(), loginToken: localStorage.getItem('token'), refId: refId, load: ln, division: division, trailer: trailerNumber, unit: unitNumber, type: "tarp", dmCode: dmCode, dispatchNumber: dispatchNumber, uploaderType: "app"}}] }))
      const requestOptions = {
        method: 'POST',
        headers: {},
        body: data
      };
      fetch(config.url.BASE_URL, requestOptions)
      .then(response => response.json())
      .then( async () => {uploadPhotos(refId)})
    }

    async function uploadPhotos(refId: any) {
      var data = new FormData();
      data.append("payload", JSON.stringify({ cmds: [{cmd: "photoUpload", args: {loginToken: localStorage.getItem('token'), refId: refId, type: "tarp", dmCode: dmCode}}] }))
      for (let i = 0; i < images.length; i++) {
        data.append(`photos${i}`, new Blob([(await b64toBlob(images[i].data))],{type: 'application/octet-stream'}));
      }
      const requestOptions = {
        method: 'POST',
        body: data
      };
      fetch(config.url.BASE_URL, requestOptions)
      .then(response => response.json())
      .then(json => {if(json.results[0].errmsg){setShowErrorModal(true); removeHashes(hashArray); setLoading(false);}else{setLoading(false); setSuccess(true); sendDmMessage(); console.log("photos successful", json.results[0]);}})
    }

    const redirectPage = () => {
      setTimeout(function() {
        window.location.replace('./Home');
      }, 3000)
    }
    
    async function takePicture() {
      const image = await Camera.getPhoto({
        quality: 30,
        allowEditing: false,
        resultType: CameraResultType.Uri
      });
      const fileName = new Date().getTime() + '.jpeg';
      // await addDateTime(image.webPath!)
      const base64Data = await base64FromPath(image.webPath!);
      if (await checkHash(base64Data)) {
        console.log("data");
        const newPhotos = [{
          filepath: fileName,
          webviewPath: image.webPath, 
          data: base64Data
        }, ...images];
        setImages(newPhotos)
        timages.push(image)
        if (timages.length > 0) {
          setPhotoMsg("Take another photo")
        }
        getUserLongLat();
        console.log("hashArray", hashArray);
      }
    }

    async function checkHash(base64Data: string) {
      var hash = CryptoJS.MD5(base64Data).toString();
      let result = false;
      await ApiService.photoChecksum("tarpPhotos", {checksum: hash}).then(it => {
        hashArray.push(hash)
        result = true;
      })
      .catch(function(err) {
        setShowErrorModal2(true);
        result = false
      })
      return result;
    }

    const removePhoto = (e: any) => {
      console.log("hashArasyaya", hashArray);
      const name = e.filepath;
      var hash = CryptoJS.MD5(e.data).toString();
      console.log("test remove", hash, hashArray);
      hashArray.forEach(async (element: any) => {
        console.log("hashArray removal", hash, element);
        if (hash === element) {
          await removeHashes([element])
          hashArray = hashArray.filter((hash: any) => hash !== element)
        }
      });
      setImages(images.filter(image => image.filepath !== name));
    }

    async function removeHashes(removalArray: any) {
      removalArray.forEach(async (element: any) => {
        await ApiService.photoChecksumRevoke("tarpPhotos", {checksum: element}).then(it => {
          hashArray = hashArray.filter((hash: any) => hash !== element)
        })
      });
    }

    async function getUserLongLat() {
      navigator.geolocation.getCurrentPosition(success, error)
    }

    async function success(pos: {coords: any; }) {
      setUserLat(await pos.coords.latitude);
      setUserLong(await pos.coords.longitude);
    }

    function error(err: any) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    
  return (
    <IonPage>
    <IonHeader>
        <IonToolbar color="primary">
        <IonButtons slot="start">
            <IonBackButton defaultHref="/Home"  text=""/>
          </IonButtons>
          <IonTitle>Tarp Pay</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toast}
        color="danger"
        duration={5000}
      />
       <IonModal isOpen={loading} id="transparent">
        <div className="modal-div">
          <div id="modal">
            <div className='loading-div'>
              <p>Please wait... your photos are being submitted.</p>
              <div className="snippet" data-title=".dot-flashing" style={{ margin: "4px 16px"}}>
                <div className="stage">
                  <div className="dot-flashing"></div>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </IonModal>
      <IonModal isOpen={showErrorModal2} id="transparent">
        <div className="modal-div">
          <div id="modal">
            <div className='warning-div' style={{backgroundColor: "#e9e9e9 !important", color: "#fff !important"}}>
              <h3 style={{textAlign: "center", fontWeight: "600"}}>Error!</h3>
              <p>This photo has already been submitted. Please select a new photo that has not been previously submitted.</p>
              <div className="snippet" data-title=".dot-flashing" style={{ margin: "4px 16px"}}>
                <div className="stage">
                  <IonButton id="western-button" expand="block" onClick={e => {setShowErrorModal2(false)}}><span style={{fontWeight: "600"}}>I Understand</span></IonButton>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </IonModal>
      <IonToast
        isOpen={successPhoto}
        onDidDismiss={() => {setLoading(false); setSuccess(false)}}
        message="Your photos were submitted."
        color="success"
        duration={3000}
      />
       <IonToast
        isOpen={showErrorModal}
        message="Error! One or more photos could not be uploaded, check your connection and please try again."
        color="danger"
        duration={5000}
      />
      <IonContent color='white'>
        <div className='main-2'>
          <div>
          <IonItem color="white" lines="none">
            <div className='item-container'>
              <h1>Submit Tarp Photos</h1>
              <p style={{fontSize: "medium"}}><b>Please submit the following photos of the load: </b>(Examples below)</p><br/>

          <div style={{ textAlign: "center"}} onClick={ e => {setHeight1(!height1); console.log("expanded");}} aria-expanded={ height1 !== false }>
            <div className='western-button-link'>
                <div id="current-load-button"  style={{background: "#e9e9e9"}} >
                    <p style={{paddingLeft: "15px"}}><b>Rear</b> view of the trailer number</p>
                    <img src={height1 == true ? arrowUp : arrowDown} style={{ height: "2vh", paddingRight: "15px"}} alt="arrow"/>
                </div>
            </div>
          </div>
          <AnimateHeight style={ height1 === true ? {overflowY: 'scroll', borderRadius: "10px", textAlign: "center" } : { overflowY: 'scroll'}} duration={500} height={ height1 === true ? 'auto' : 0 } id="loadSection">
            <img src={trailerRear} alt="Rear Trailer Image" style={{borderRadius: "8px", textAlign: "center"}}/>
          </AnimateHeight>

          <div style={{ textAlign: "center"}} onClick={ e => {setHeight2(!height2); console.log("expanded");}} aria-expanded={ height2 !== false }>
            <div className='western-button-link'>
                <div id="current-load-button" style={{background: "#e9e9e9"}}>
                    <p style={{paddingLeft: "15px"}}><b>Side</b> view of the tarped load</p>
                    <img src={height2 === true ? arrowUp : arrowDown} style={{ height: "2vh", paddingRight: "15px"}} alt="arrow"/>
                </div>
            </div>
          </div>
          <AnimateHeight style={ height2 === true ? {overflowY: 'scroll', borderRadius: "10px", textAlign: "center" } : { overflowY: 'scroll'}} duration={500} height={ height2 === true ? 'auto' : 0 } id="loadSection">
            <img src={trailerSide} alt="Side Trailer Image" style={{borderRadius: "8px"}}/>
          </AnimateHeight>

          <div style={{ marginBottom: "14px", textAlign: "center"}} onClick={ e => {setHeight3(!height3); console.log("expanded");}} aria-expanded={ height3 !== false }>
            <div className='western-button-link'>
                <div id="current-load-button"  style={{background: "#e9e9e9"}} >
                    <p style={{paddingLeft: "15px"}}><b>Front</b> view of the tarped load</p>
                    <img src={height3 === true ? arrowUp : arrowDown} style={{ height: "2vh", paddingRight: "15px"}} alt="arrow"/>
                </div>
            </div>
          </div>
          <AnimateHeight style={ height3 === true ? {overflowY: 'scroll', borderRadius: "10px", textAlign: "center" } : { overflowY: 'scroll'}} duration={500} height={ height3 === true ? 'auto' : 0 } id="loadSection">
            <img src={trailerFront} alt="Front Trailer Image" style={{borderRadius: "8px"}}/>
          </AnimateHeight>

              <button className='button' style={{margin: "18px auto"}} onClick={e => {takePicture()}}><p>Take Photo</p><IonIcon icon={cameraOutline}></IonIcon></button>
            </div>
          </IonItem>
          </div>
          <div>
            <div style={{ display: "grid", gridTemplateColumns: '1fr 1fr 1fr', padding: "8px", backgroundColor: "#ffffff", margin: "auto", textAlign: "center"}} >
              {images.map(function(img, index) {
                return <div key={index} onClick={e => {removePhoto(img)}} style={{position: "relative"}}>
                  <img alt="tarppay img"  style={{width: "100px", borderRadius: "10px"}}src={img.webviewPath} />
                  <button style={{ backgroundColor: "rgba(0,0,0,0)", borderRadius: "50%", width: "32px", height: "32px", position: "absolute", top: "0", left: "0"}}><IonIcon style={{color: "#ff0000", fontSize: "large"}} icon={trashOutline}></IonIcon></button>
                </div>
              })}
            </div>
          </div>
        </div>
          <IonItem lines="none" color="white">
            <div className='item-container'>
              <button className="western-button submit" disabled={submitDisabled}  onClick={e => {submitReport()}}>SUBMIT FOR TARP PAY</button>
            </div>
          </IonItem>
      </IonContent>
 
    </IonPage>
  );
};

export default PhotoUpload;

