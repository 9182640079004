import React from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonLabel, IonItem, IonButtons, IonBackButton, IonList } from '@ionic/react';
import { Markup } from 'interweave';
import HelpDeskEntry from './HelpDeskEntry';


const Payroll: React.FC = () => {
      let questionsAndAnswers = [
        {question: `How do I turn in BOL to get paid?`, answer: `Check out this video or view this <a href="http://www.westernexp.com/wp-content/uploads/2019/07/TRANSFLO_Mobile_Doc.pdf" target="_blank">guide to using transflow</a>.`},
        {question: `When is the payroll cut-off?`, answer: `You must turn in your BOL via the transflo mobile+ app Saturday before midnight (23:59) to be paid the following week.`},
        {question: `How do I access my payroll on my EFS card?`, answer: `The most important item is that your EFS Smartfunds Driver profile needs to be set up! You have two options to set up your profile: Option 1: Visit the EFS website (www.efsllc.com). Go to the website then log in the top right corner and select eManager. The enter your 19 digit card number and your 4 digit pin. Select edit Smartfund Driver Profile on the right hand side. Fill out the form. It should take about 5 minutes. Option 2: You can download the EFS Mobile app from the app store. You are immediately taken to your Driver Profile before accessing the Smartfunds menu. Once this is set up, you will have access to your Smartfunds. You can use your fuel card like a debit card at any ATM by using the CHECKING side of the card. You will also be able to call EFS and have money registered to an EFS Check. EFS also has a mobile app you can download to your smartphone to access your account balances, ACH transfers to personal bank accounts and more.`},
        {question: `Why was I not paid for a load?`, answer: `Each load needs a separate trip (bubble) sheet. You need to include the bills if you completed the load. If not, you will only need to send in the trip (bubble) sheet. You may also need to send in any relevant receipts for that load (i.e. tolls, scales tickets, lumper, etc). *** You will not be reimbursed for items not transflo'd in with the correct trip ****. Starting the week of 12/25/2016, paperwork must be turned in by Saturday @ Midnight ****`},
        {question: `How do I access my 2020 payroll information?`, answer: `PAYROLL IS NO LONGER IN ADP. ACCESS YOUR PAYROLL HERE: <a href="https://wd5.myworkday.com/wday/authgwy/westernexp/login.htmld" target="_blank">WORKDAY PAYROLL</a> FOR FURTHER INSTRUCTIONS: <a href="http://www.westernexp.com/workday/" target="_blank">WORKDAY INSTRUCTIONS</a>`},
        {question: `Do we still use ADP for payroll?`, answer: `NO PAYROLL IS NO LONGER IN ADP. ACCESS YOUR PAYROLL <a href="https://wd5.myworkday.com/wday/authgwy/westernexp/login.htmld" target="_blank">HERE</a>.`},
      ]

  return (
    <IonPage>
      <IonHeader >
        <IonToolbar color="primary">
        <IonButtons slot="start">
            <IonBackButton defaultHref="/Home"  text=""/>
          </IonButtons>
          <IonTitle>Payroll F.A.Q.</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen id="payroll-faq">

        
       <IonList color="light">
       <IonItem lines="none" color="light"><div className="item-container"><h1><strong>Frequently Asked Questions</strong></h1></div></IonItem>
        {questionsAndAnswers.map(function(element, index){
            let entry = 
            <HelpDeskEntry key={index} element={element}/>
            return entry
        })}
       </IonList>
          {/* <h1 style={{textAlign:"center"}}>
           FAQ
          </h1>
          {questionsAndAnswers.map(function(qanda, idx){
            return <IonItem key={idx}>
                    <IonLabel className="ion-text-wrap">
                      <h2 className="question">{qanda.question}</h2>
                      <Markup content={qanda.answer} />
                    </IonLabel>
                  </IonItem>
        })} */}
      </IonContent>
    </IonPage>
  );
};

export default Payroll;
