import { IonItem} from '@ionic/react'
import React from 'react'

const GuideSlide = (props:any) => {

    return (
                <IonItem lines="none" color='white'>
                    <div className="item-container">
                        <h1><strong>{props.options.title}</strong></h1>
                        <p className='subtitle'>{props.options.subtitle}</p>
                        {props.options.imgText ? <div className='image-text'>
                            <img src={props.options.img} alt="img-txt"/>
                            <p>
                               {props.options.imgText}
                            </p>
                        </div> : null}
                        <br/>
                        {props.options.content}
                        {/* <p style={{ fontSize: "small"}}>
                            Have every detail of your shipment ready for delivery.
                            Upload tarp photos and get paid! Review past shipments. Track when your next raise
                            is, and your current rate of pay. See where you rank in your division for miles and loads.
                            Request cash advances, and view important documents.
                        </p> */}
                    </div> 
                </IonItem>
                
    )
}

export default GuideSlide