import React from "react"
import historyIcon from "../imgs/history.png"
import preplanIcon from "../imgs/preplan.png"
import {LoadInfo} from "../api/model/LoadInfo";
import {Link} from "react-router-dom";
import {UserState, userStore} from "../api/AuthService";
import {DateUtils} from "../utils/DateUtils";

const Load = () => {

    const {load}: { load: LoadInfo } = userStore((state: UserState) => ({load: state.loadInfo}));

    return (
        <div className="item-container">
            <div className="grid50">
                <Link to="/currentload" className="western-button-link">
                    <div id="centered">
                        <p>Current Load</p>
                        {load ? <>
                            <p>{DateUtils.formatDate(load?.deliveryStartTime)}</p>
                            <p>{load?.consigneeCity}, {load?.consigneeState}</p>
                        </> : <p className="color-danger">No active load found!</p>}
                    </div>
                </Link>
                <div>
                    <Link to="/loadhistory" className="western-button-link">
                        <div>
                            <img src={historyIcon} alt="key" className="link-icon"/><p>Load History</p>
                        </div>
                    </Link>
                    <Link to="/preplanned" className="western-button-link">
                        <div>
                            <img src={preplanIcon} alt="key" className="link-icon"/><p>Preplanned</p>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}
export default Load
