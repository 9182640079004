import React, {useEffect, useState} from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonModal,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import {GoogleApiWrapper, Map, Marker} from 'google-maps-react';
import {base64FromPath} from '@ionic/react-hooks/filesystem';
import {Camera} from '@capacitor/camera';
import Loader from 'react-loader-spinner';
//Google Analytics:
import {getAnalytics, logEvent} from 'firebase/analytics';
import {initializeApp} from "firebase/app";
import {cameraOutline, trashOutline} from 'ionicons/icons';
import {ApiService} from "../api/ApiService";
import {toastStore} from "../components/AppToast";
import {PhotoApiService} from "../api/PhotoApiService";
import {DriverProfile} from "../api/model/DriverProfile";
import {UserState, userStore} from "../api/AuthService";
import AppLoading from '../components/AppLoading';

const firebaseConfig = {
    apiKey: "AIzaSyDE8xEN_qtMPO-aBkTon7S7kKcyUZJD5gc",
    authDomain: "western-express-5002c.firebaseapp.com",
    databaseURL: "https://western-express-5002c.firebaseio.com",
    projectId: "western-express-5002c",
    storageBucket: "western-express-5002c.appspot.com",
    messagingSenderId: "799211811572",
    appId: "1:799211811572:web:34706f63a8621cb0b0a4a1",
    measurementId: "G-V5MNJV768B"
  }
  
const app = initializeApp(firebaseConfig);

const handleEventLog = (eventType: string, info: string) => {
  const analytics = getAnalytics(app);
  logEvent(analytics , eventType, {eventType: info})
  console.log(eventType);
}
const timages: any[] = [];

interface Photo {
  filepath: string;
  webviewPath?: string;
  data: any;
}

type trailer = {
  distance: number,
  lastPing: string,
  latitude: number,
  longitude: number,
  asset_id: string,
}

const TrailerTracking: React.FC = () => {

  const {userInfo}: { userInfo: DriverProfile } = userStore((state: UserState) => ({userInfo: state.driver}));

  let distanceArray: number[] = [];
  const [trailers, setTrailers] = useState<trailer[]>([]);
  const [images, setImages] = useState<Photo[]>([]);
  const [trailerNumber, setTrailerNumber] = useState<string>('');
  const [reportType, setReportType] = useState<string>('breakdown');
  const [userLat, setUserLat] = useState<number>(36.1627);
  const [userLong, setUserLong] = useState<number>(-86.7816);
  const [lati, setLati] = useState<number>(36.1627);
  const [long, setLong]= useState<number>(-86.7816);
  const [zoomLevel, setZoomLevel] = useState(3);
  const [reportedTrailer, setReportedTrailer] = useState('');
  const [selectedTrailerIndex, setSelectedTrailerIndex] = useState(0);
  const [showPhotoSubmit, setShowPhotoSubmit] = useState(false);
  const [comments, setComments] = useState('');
  const [connecting, setConnecting] = useState('');
  const [photoMsg, setPhotoMsg] = useState<string>("Take photo");
  const [showModal1, setShowModal1] = useState(false);
  const [showInfoWindow, setShowInfoWindow] = useState(false);

  useEffect(() => {
    getUserLongLat();
    ApiService.sendEventCommand("Trailer Check", "eventCheck", "User has view the Trailer Tracking page");
  }, []);

  enum CameraResultType {
    Uri = 'uri',
    Base64 = 'base64',
    DataUrl = 'dataUrl'
  }

    async function processTrailers(trailerList: any) {
      let tempArray: any = [];
        let tempObj = {asset_id: '', distance: ''}
        tempObj = trailerList;
        tempObj.asset_id = userInfo.trailer;
        tempArray.push(tempObj);
        getDistances(lati, long, tempObj);
        setTrailers(tempArray);
    }

  function requestTrailer() {
    const body = 'Driver ' + userInfo.driverCode + ' is requesting a trailer with unit ' + userInfo.unit + ". To view this driver's location, go to: http://software.westernexp.com/admin-console/trailertracking and enter the driver code. " + "Use the Admin console messaging to reply to this driver: " + 'http://software.westernexp.com/admin-console/messaging.';
    ApiService.sendEmail("trailer tracking", {to: ["wsxi-AssetMgmt@westernexp.com", userInfo.dmEmail, "nighttracking@westernexp.com", "weekendtracking@westernexp.com"], subject: 'Trailer request', body}).then(() => {
      sendInboxMessage();
    });
  }

  function sendInboxMessage() {
    ApiService.inboxSendMessage("trailer tracking", {
      body: "Driver " + userInfo.driverCode + " is requesting a trailer with unit " + userInfo.unit + ".",
      subject: userInfo.driverCode,
      type: 'dm',
      toUserId: userInfo.dmCode,
      fromUserId: userInfo.driverCode
    }).then(() => {
      toastStore.getState().setSuccessToast('Your request has been sent to your DM');
    });
  }

  async function findCurrentTrailer() {
    if (!userInfo.trailer) {
      toastStore.getState().setToast('No trailer currently assigned');
      return;
    }

    ApiService.getTrailerLocation("trailer tracking", {trailerNumber: userInfo.trailer}).then(it => {
      processTrailers(it);
    });
  }

    function getUserLongLat() {
      navigator.geolocation.getCurrentPosition(success, error)
    }

    async function success(pos: {coords: any; }) {
      setUserLat(await pos.coords.latitude);
      setUserLong(await pos.coords.longitude);
    }

    function error(err: any) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }

  async function connectTrailer() {
    ApiService.trailerConnect("trailer tracking", {trailerNumber, unitNumber: userInfo.unit}).then(() => {
      toastStore.getState().setSuccessToast(`You are now connected to trailer ${trailerNumber}`);
    });
  }

  const handleReject = () => {
    if (comments === '' && images.length === 0 && reportType === '') {
      toastStore.getState().setToast('Please take a photo and leave a comment as to why you are reporting the trailer');
    } else {
      sumbitRejection();
    }
  }

  function sumbitRejection() {
    setReportedTrailer(trailerNumber || trailers[selectedTrailerIndex].asset_id);
    ApiService.trailerReject("trailer tracking", {trailerNumber: reportedTrailer, rejectionType: reportType, comment: comments, unitNumber: userInfo.unit}).then(() => {
      setConnecting('');
      submitPhotos((it as any).rejectionId);
      setShowModal1(false);
    });
  }

  function getDistances(latit: number, longi: number, trails: any) {
    let tempLat: number = trails.latitude;
    let tempLng: number = trails.longitude;
    let userLatLong = new google.maps.LatLng(latit, longi);
    if ((trails.latitude && trails.longitude) !== 0) {
        let latLong = new google.maps.LatLng(tempLat, tempLng)
        // let dist = google.maps.geometry.spherical.computeDistanceBetween(latLong, userLatLong) / 1000;
        // distanceArray.push(dist);
        // trails.distance = dist * 0.621371;
        setLati(trails.latitude);
        setLong(trails.longitude);
      }
    getZoomArea();

  }


  function getZoomArea() {
    let farthestPoint = Math.max(...distanceArray)
    if (farthestPoint >= 2200) {
      setZoomLevel(2)
    } else if (farthestPoint >= 800 ) {
      setZoomLevel(4)
    } else if (farthestPoint >= 200) {
      setZoomLevel(6)
    } else if (farthestPoint >= 100) {
      setZoomLevel(7)
    } else if (farthestPoint >= 30) {
      setZoomLevel(9)
    } else {
      setZoomLevel(12)
    }
  }

  async function takePicture() {
    const image = await Camera.getPhoto({
      quality: 30,
      allowEditing: false,
      resultType: CameraResultType.Uri
    });
    const fileName = new Date().getTime() + '.jpeg';
    const base64Data = await base64FromPath(image.webPath!);
    const newPhotos = [{
      filepath: fileName,
      webviewPath: image.webPath, 
      data: base64Data
    }, ...images];
    setImages(newPhotos)
    timages.push(image)
    console.log(images);
    if (timages.length > 0) {
      setPhotoMsg("Take another photo")
    }
  }

  async function submitPhotos(refId: any) {
    setShowModal1(false);
    setShowPhotoSubmit(true);
    if (!images.length) {
      return;
    }

    setConnecting('uploading');
    ApiService.photoSubmission("trailer tracking", {
      type: 'trailerReport',
      refId: refId,
      dmCode: userInfo.dmCode,
      division: userInfo.division,
      unit: userInfo.unit,
      trailer: userInfo.trailer,
      load: userStore.getState().loadNumber
    }).then(() => {
      PhotoApiService.uploadPhotos(images, refId, 'trailerReport').then(() => {
        setConnecting('');
        setShowModal1(false);
        resetReport();
      });
    });
  }

  const removePhoto = (e: any) => {
    const name = e.filepath;
    setImages(images.filter(image => image.filepath !== name));
  }

  function resetReport() {
    setImages([]);
    setReportType('');
    setComments('');
  }


  function handleOpenOptions () {
    let box = document.getElementById("report-box")!
    box.style.top = "10vh"
  }
  return (
    <IonPage>
    <IonHeader >
        <IonToolbar color="primary">
        <IonButtons slot="start">
            <IonBackButton defaultHref="/Home"  text=""/>
          </IonButtons>
          <IonTitle>Tracking</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
      <IonModal isOpen={showModal1} cssClass='my-custom-class'>

      { showInfoWindow  && connecting === ''?
      <IonContent>
        <IonItemDivider color="primary" id="modalheader">
          <IonLabel className="ion-text-wrap" style={{width: '100%'}}><h1 style={{textAlign:"center"}}>Trailer: {trailers[selectedTrailerIndex].asset_id || trailerNumber}</h1></IonLabel>
        </IonItemDivider>
        <IonList>
          <IonItem>
            <IonLabel>Distance</IonLabel>
            <IonNote slot="end" color="black">{Math.round(trailers[selectedTrailerIndex].distance * 100) / 100} Miles</IonNote>
          </IonItem>
          <IonItem>
            <IonLabel>Last Ping</IonLabel>
            <IonNote slot="end" color="black">{trailers[selectedTrailerIndex].lastPing.replace('T', ' ').slice(0,-14)}</IonNote>
          </IonItem>
            <IonButton className="home" fill="outline" color="primary" size="large" expand="block" onClick={ e => {setConnecting('connect')}}>Connect Trailer</IonButton>
            <IonButton className="home" fill="outline" color="primary" size="large" expand="block" onClick={ e => {setConnecting('reject'); setReportedTrailer(trailers[selectedTrailerIndex].asset_id || trailerNumber)}}>Report Trailer</IonButton>
            <IonButton className="home" fill="outline" color="secondary" size="large" expand="block" onClick={ e => {setShowModal1(false); setTrailerNumber('')}}>Cancel</IonButton>
        </IonList>
      </IonContent>

       : <> {connecting === 'connect' ?
          <IonContent>
          <IonItemDivider color="primary" id="modalheader">
            <IonLabel className="ion-text-wrap" style={{width: '100%'}}><h1 style={{textAlign:"center"}}>Connect to Trailer</h1></IonLabel>
          </IonItemDivider>
            <IonLabel style={{padding: '4px'}} className="ion-text-wrap">                  
                    <p style={{paddingRight: '40px', paddingLeft: '40px', color: 'white'}}><h2 style={{textAlign: 'center'}}>Confirm that you have connected to the trailer to your assigned unit</h2></p>
            </IonLabel>
              <IonButton className="home" fill="outline" color="primary" size="large" expand="block" onClick={ e => {setShowModal1(false); connectTrailer(); setConnecting('')}}>Connect</IonButton>
            <IonButton className="home"  fill="outline" color="secondary" size="large" expand="block" onClick={ e => {setConnecting('')}}>Cancel</IonButton>
          </IonContent> 

        : (connecting === 'request') ?
        <IonContent>
        <IonItem color="light" lines="none">
          <div className='item-container'>
            <p className='title'>Request Trailer</p>
            <p className='subtitle'>Confirm that you want to request trailer to your assigned unit</p> 
          </div>
        </IonItem>
        <IonItem color="light" lines="none">
          <div className='item-container'>
            <button className="western-button submit" onClick={ e => {setShowModal1(false);requestTrailer(); handleEventLog("request_Trailer", "access-GPS_Location")}}>Send Request</button>
           </div>
        </IonItem>
        <IonItem color="light" lines="none">
          <div className='item-container'>
          <button className="button" onClick={ e => {setConnecting(''); setShowModal1(false);}}>Cancel</button>
          </div>
        </IonItem>
        </IonContent> 
          
        : (connecting === 'reject') ?
          <IonContent>
            <IonItem lines="none" color="light">
              <div className='item-container'>
                  <h1 className='title'>Report Trailer</h1>
              </div>
            </IonItem>
              <IonItem lines="none" color="light">
                  <div className="item-container">
                    <button className='button'  onClick={takePicture}><p>{photoMsg}</p><IonIcon icon={cameraOutline}></IonIcon></button>
                  </div>
                </IonItem>
                <IonItem lines="none" color="light">
                <div style={{ display: "grid", gridTemplateColumns: '1fr 1fr 1fr', padding: "8px"}} >
            {images.map(function(img, index) {
              return <div key={index} onClick={e => {removePhoto(img)}} style={{position: "relative"}}>
                <img alt="trailer img"  style={{width: "100px", borderRadius: "10px", marginRight: '8px'}}src={img.webviewPath} />
                <button style={{ backgroundColor: "rgba(0,0,0,0)", borderRadius: "50%", width: "32px", height: "32px", position: "absolute", top: "0", left: "0"}}><IonIcon style={{color: "#ff0000", fontSize: "large"}} icon={trashOutline}></IonIcon></button>
              </div>
            })}</div>
                </IonItem>
                <IonItem lines="none" color="light">
            <div className="item-container">
                <div className="input-container">
                    <select id="type" className="input"  placeholder=" " value={reportType} onChange={e => setReportType(e.target.value!)} style={{ height: "20px"}}>
                      <option value="breakdown">Breakdown</option>
                      <option value="missing equipment">Missing Equipment</option>
                      <option value="registration">Registration</option> 
                      <option value="other">Other</option>
                    </select>
                    <p className="placeholder" >Select Terminal</p>
                </div>
            </div>
          </IonItem>
          <IonItem lines="none" color="light">
            <div className='item-container'>
              <div className="input-container">
                  <textarea id="comment-box" className="input"  placeholder=" " value={comments} onChange={e => setComments(e.target.value!)}/>
                  <p className="placeholder" >Comments</p>
              </div>
            </div>
          </IonItem>
          <IonItem lines="none" color="light">
            <div className='item-container'>
              <button className="western-button submit"  onClick={ e => {setShowModal1(false); handleReject(); handleEventLog("submit_TrailerReport", "submit-TrailerTracking")}}>Report</button>           
            </div>
          </IonItem>
          <IonItem lines="none" color="light">
            <div className='item-container'>
              <div className="input-container">
                <button className="button" onClick={ e => {setConnecting(''); setShowModal1(false); resetReport()}}>Cancel</button>
              </div>
            </div>
          </IonItem>
        </IonContent> : 
      (showPhotoSubmit && connecting === '') ? <> <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>Thank You</IonCardSubtitle>
          <IonCardTitle>Your photo has been submitted.</IonCardTitle>
        </IonCardHeader>
        </IonCard>    
        <IonButton expand="block" className="home" fill="outline" color="primary" strong size="large" href="/Home" onClick={ function() {setShowModal1(false); handleEventLog("submit_TrailerPhoto", "submit-TrailerPhoto")}}>Done</IonButton> </> :
          (showPhotoSubmit && connecting === 'uploading') ?
            <IonCard>
            <IonCardHeader>
              <IonCardSubtitle>Please Wait</IonCardSubtitle>
              <IonCardTitle style={{display: 'flex'}}>Your photo is uploading
                <Loader type="Circles" color="#95d201" justify-content={'center'} height={50} width={50}/>
              </IonCardTitle>
            </IonCardHeader>
          </IonCard> : null } </> }
        </IonModal>

          <div style={{position: 'fixed', height: '80vh', top: 0, zIndex: 2, width: "100%" }}>
            <Map google={google} zoom={zoomLevel} center={{ lat: lati, lng: long }}>
            <Marker icon={'/assets/icon/tkbl.png'} position={{lat: userLat, lng: userLong}}/>
              {trailers.map(function(element) {
                let trailerMarker = 
                <Marker icon={'/assets/icon/trailer_map.png'} onClick={e => {setShowInfoWindow(true); setShowModal1(true); setSelectedTrailerIndex(trailers.indexOf(element)); setTrailerNumber(element.asset_id)}} position={{lat: element.latitude, lng: element.longitude}} />
                return trailerMarker;
              })}
            </Map>
          </div>
          <div id="report-box"className="container" style={{marginTop: "12px", borderRadius: "10px", position: "absolute", width: "100%", height:"90vh", backgroundColor: "#f4f5f8", top: "80vh", zIndex: 3}} draggable onDragStart={e => console.log(e)}>
          
  
           <div id="pull-tab"></div>
           
           <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", marginTop: "12px"}}><input  value={trailerNumber} onChange={e => {setTrailerNumber(e.target.value!)}} style={{ backgroundColor: "#ffffff", borderRadius: "10px", border: "none", outline: "none", color: "black", fontFamily: "Montserrat, sans-serif", fontWeight: 500, padding: "16px", width: "80%"}} type="text" placeholder="Report a trailer"/><p style={{ color: "#5096FF"}}  onClick={e => {setShowModal1(true); setConnecting('reject'); setReportedTrailer(trailerNumber);}}>Report</p></div>
           <p className='subtitle' style={{color:"#FA8080", margin: "8px"}} >*Only accepts valid trailer numbers</p>
          
          
            {/* <IonItem><button id="western" onClick={e => {setShowModal1(true); setConnecting('reject'); setReportedTrailer(trailerNumber);}}>Report</button></IonItem> */}
            <IonItem lines="none" color="light">
              <div className='item-container'>
                <button className="western-button submit" onClick={e => {findCurrentTrailer(); handleEventLog("locate_Trailer", "access-GPS_Location") }}>Find My Trailer: {userInfo.trailer}</button>
              </div>
            </IonItem>
            <IonItem lines="none" color="light">
              <div className='item-container'>
                <button className="western-button submit" onClick={e => {setShowModal1(true); setConnecting('request');}}>Request a Trailer</button>
              </div>
            </IonItem>
        </div>

      </IonContent>
    </IonPage>
  );
};

export default GoogleApiWrapper({
  apiKey: ('AIzaSyDE5dkptu8phPLX8I_dHput3cJFt1xyfL0')
})(TrailerTracking)

  
