import {userStore} from "../api/AuthService";
import {UserType} from "../api/enum/UserType";

export class UserUtils {
    public static isAdmin(): boolean {
        return userStore.getState().type === UserType.ADMIN;
    }

    public static isDriverManager(): boolean {
        return userStore.getState().type === UserType.DRIVER_MANAGER;
    }

    public static isDriver(): boolean {
        return userStore.getState().type === UserType.DRIVER;
    }

    public static isTrainee(): boolean {
        return userStore.getState().type === UserType.TRAINEE;
    }

    public static isInspector(): boolean {
        return userStore.getState().type === UserType.INSPECTOR;
    }

    public static isInstructor(): boolean {
        return userStore.getState().type === UserType.INSPECTOR;
    }

    public static isTrainerFeedbackEnabled(): boolean {
        return UserUtils.isTrainee() && false; // Second boolean is for enabling/disabling the trainer feedback form in the app. False - makes it disabled and inaccessible from anywhere.
    }

}
