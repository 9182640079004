import React, {useEffect, useState} from 'react';
import {IonAlert, IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import HelpDeskEntry from './HelpDeskEntry';
import scanIcon from '../imgs/work/qr-scan.png';
import {Link} from "react-router-dom";
import {ApiService} from "../api/ApiService";
import {FaqEntry} from "../api/model/FaqEntry";
import {PlatformUtils} from "../utils/PlatformUtils";

const HelpDesk: React.FC = () => {

const [payrollFAQToggle, setPayrollFAQToggle] = useState(0);
const [transfloFAQToggle, setTransfloFAQToggle] = useState(0);
const [currentRate, setCurrentRate] = useState(0);
const [daysUntilRaise, setDaysUntilRaise] = useState(0);
const [payrollStartDate, setPayrollStartDate] = useState("");
const [showAlert, setShowAlert] = useState(false);
const [storeLink, setStoreLink] = useState('');
const [FAQEntries, setFAQEntries] = useState<FaqEntry[]>([]);

    useEffect(() => {
        ApiService.sendEventCommand("Helpdesk View", "eventCheck", "User viewed Helpdesk entries");
        ApiService.getFaqEntries("helpDesk").then(it => setFAQEntries(it));
        ApiService.getPayrollOverview("helpDesk").then(it => {
            setCurrentRate(it.CURRENT_FULL_SOLO_RATE);
            setDaysUntilRaise(it.DAYS_TILL_NEXT_RATE);
            setPayrollStartDate(it.INITIAL_DATE.replace('T', ' ').slice(0, -14));
        });
        setStoreLink(PlatformUtils.getStoreLink());
    }, []);

  const payrollToggle = () => {
    {payrollFAQToggle === 1 ? setPayrollFAQToggle(0) : setPayrollFAQToggle(1)}
  }

  const transfloToggle = () => {
    {transfloFAQToggle === 1 ? setTransfloFAQToggle(0) : setTransfloFAQToggle(1)}
  }

  function downloadTransflo() {
    window.open(storeLink)
  }

  return (
    <IonPage>
     <IonHeader >
        <IonToolbar color="primary">
        <IonButtons slot="start">
            <IonBackButton defaultHref="/Home"  text=""/>
          </IonButtons>
          <IonTitle>Help Desk</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
    
      <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={"You don't currently have the Transflo+ app installed. Would you like to download it from the app store?"}
          message={"Reminder: when registering with Transflo+, please use WSXI as the Recipient ID."}
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary'
            },
            {
              text: 'Download',
              handler: () => {
                downloadTransflo();
              }
            }
          ]}
        />

     
       <IonItem lines="none" color="light"><div className="item-container"><h1><strong>Frequently Asked Questions</strong></h1></div></IonItem> 
          <div className="question-box" onClick={payrollToggle}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}><p style={{ width: "80%"}}>What is my rate of pay? When is my next raise?</p>{payrollFAQToggle ? <div className='letter-icon' style={{ backgroundColor: "#FF8575"}}><p>-</p></div>: <div className='letter-icon' style={{ backgroundColor: "#9975FF"}}><p>+</p></div>}</div>
            <p style={{ fontSize: "small"}} id={payrollFAQToggle ? "expanded" : "collapsed"}> Current Solo Rate: {currentRate}<br/><br/>Days Until Next Raise: {daysUntilRaise}<br/><br/>Payroll Start Date: {payrollStartDate}</p>
          </div>
          <div className="question-box" onClick={transfloToggle}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}><p  style={{ width: "80%"}}>What is the transflow process so I receive pay on time?</p><p>{transfloFAQToggle ?  <div className='letter-icon' style={{ backgroundColor: "#FF8575"}}><p>-</p></div>: <div className='letter-icon' style={{ backgroundColor: "#9975FF"}}><p>+</p></div>}</p></div>
            <div id={transfloFAQToggle ? "expanded-grid" : "collapsed"} style={{ gridTemplateColumns: "2fr 2fr"}}>
                <p style={{ fontSize: "small"}}>Bills must be scanned after each load delivers. You can scan them through the Scan Docs button on the Work page.</p>
                <Link className="western-button-link" to="/Home">
                    <div id="centered">
                        <p>Scan Docs</p>
                        <img src={scanIcon} alt="scan"/>
                    </div>
                </Link>
            </div>
          </div>
        {FAQEntries.map(function(element){
          if (element.active !== 0) {
            let entry = 
            <HelpDeskEntry element={element}/>
            return entry
          }
        })}
      
      </IonContent>
    </IonPage>
  );
};

export default HelpDesk;
