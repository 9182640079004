import {Capacitor} from "@capacitor/core";
import {PushNotifications} from "@capacitor/push-notifications";
import {ApiService} from "./ApiService";
import {isPlatform} from "@ionic/react";

export class PushNotificationsService {

    static eventArray: any = [];

    public static async registerAfterAppLogin(userId: string) {
        if (Capacitor.isPluginAvailable('PushNotifications')) {
            if (JSON.parse(localStorage.getItem('pushNotificationPermission')!) === null) {
                const permissionGranted = PushNotifications.requestPermissions();
                if ((await permissionGranted) && JSON.parse(localStorage.getItem('pushNotificationPermission')!) === null) {
                    PushNotifications.register();
                } else {
                    localStorage.setItem('refusalDate', JSON.stringify(new Date()));
                }
            } else {
                PushNotifications.register();
            }

            PushNotifications.addListener('registration',
                async (token) => {
                    localStorage.setItem('pushNotificationPermission', token.value);
                    await ApiService.registerDeviceToken("pushNotificationsService", {platform: isPlatform('ios') ? 'ios' : 'android', userId});
                }
            );

            PushNotifications.addListener('registrationError',
                (error: any) => {
                    console.log('Error on registration: ' + JSON.stringify(error))
                    alert('Error on registration: ' + JSON.stringify(error));
                }
            );
        }
    }

    public static registerAfterAppLoad() {
        if (Capacitor.isPluginAvailable('PushNotifications') && this.eventArray.length <= 1) {
            this.eventArray.pop();
            PushNotifications.removeAllListeners();
            this.eventArray.push('pushNotificationReceived')
            for (let index = 0; index < this.eventArray.length; index++) {
                PushNotifications.addListener('pushNotificationReceived', (notification) => {
                        // alert(notification.body);
                        console.log("pushNotificationReceived", notification.body, notification);
                    }
                );
            }
        }
    }


}
