import React, {createRef, useEffect, useState} from "react";
import {IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonItemGroup, IonModal, IonPage, IonTitle, IonToolbar} from "@ionic/react";
import {alertCircleOutline, cameraOutline, close, trashOutline} from 'ionicons/icons';
import PassengerPolicy from "./PassengerPolicy";
import SignaturePad from "react-signature-canvas"
import ReactSignatureCanvas from "react-signature-canvas"
import {PDFDocument} from "pdf-lib"
import {Camera} from "@capacitor/camera";
import {base64FromPath} from '@ionic/react-hooks/filesystem';

//Google Analytics:
import {getAnalytics, logEvent} from 'firebase/analytics';
import {initializeApp} from "firebase/app";
import {ApiService} from "../api/ApiService";
import {toastStore} from "../components/AppToast";
import {DriverProfile} from "../api/model/DriverProfile";
import {UserState, userStore} from "../api/AuthService";
import {useHistory} from "react-router-dom";

const timages: any[] = [];

function PassengerForm() {
  enum CameraResultType {
    Uri = 'uri',
    Base64 = 'base64',
    DataUrl = 'dataUrl'
  }

    const history = useHistory();
    const {userInfo, username}: { userInfo: DriverProfile, username: string } = userStore((state: UserState) => ({userInfo: state.driver, username: state.username}));

    const today = new Date()
    const [showToast, setShowToast] = useState(false)
    const [passenger, setPassenger] = useState("")
    const [dateOfBirth, setDateOfBirth] = useState("")
    const [governmentId, setGovernmentId] = useState("")
    const [beneficiary, setBeneficiary] = useState("")
    const [relationship, setRelationship] = useState("")
    const [showModal, setShowModal] = useState(true)
    const [dataURL, setDataURL] = useState("")
    const [signatureData, setSignatueData] = useState("")
    const [driverSignatureData, setDriverSignatueData] = useState("")
    const [showDriverSignature, setShowDriverSignature] = useState(true)
    const [showPassengerSignature, setShowPassengerSignature] = useState(true)
    const [photoMsg, setPhotoMsg] = useState<string>("Take photo");
    const [images, setImages] = useState("");
    const [imageWebpath, setImageWebpath] = useState("")

    const firebaseConfig = {
      apiKey: "AIzaSyDE8xEN_qtMPO-aBkTon7S7kKcyUZJD5gc",
      authDomain: "western-express-5002c.firebaseapp.com",
      databaseURL: "https://western-express-5002c.firebaseio.com",
      projectId: "western-express-5002c",
      storageBucket: "western-express-5002c.appspot.com",
      messagingSenderId: "799211811572",
      appId: "1:799211811572:web:34706f63a8621cb0b0a4a1",
      measurementId: "G-V5MNJV768B"
    }
    
    const app = initializeApp(firebaseConfig)

    useEffect(() => {
    }, []);
    
    const handleEventLog = (eventType: string, info: string) => {
      const analytics = getAnalytics(app);
      logEvent(analytics , eventType, {eventType: info})
      console.log(eventType);
    }

    const b64toBlob = (base64:any) => 
    fetch(base64).then(res => res.blob())

    async function submitForm(data: string, data2: string) {
      let tempFile = new Blob([(await b64toBlob(data))], {type: 'application/pdf'})
      let tempFile2 = new Blob([(await b64toBlob(data2))], {type: 'image/png'})
        ApiService.sendFileEmail("passengerForm", {
            email: "benefitshelp@westernexp.com",
            subject: "Passenger Authorization Form",
        },
        {
          file1: {
            fileName: "Passenger Form.pdf",
            file: tempFile,
          },
          file2: {
            fileName: "Driver's License.png",
            file: tempFile2
          }
        })
        .then(() => {ApiService.sendEventCommand("Passenger Form", "eventCheck", "User submitted a passenger form")})
        .then(() => {
            setShowToast(false);
            toastStore.getState().setSuccessToast('Success. Your form was submitted.');
            history.push('/');
        });
    }

    const sigCanvas = createRef<ReactSignatureCanvas>()
    const driverSigCanvas = createRef<ReactSignatureCanvas>()

    /* a function that uses the canvas ref to clear the canvas 
    via a method given by react-signature-canvas */
    const clear = () => sigCanvas.current!.clear()
      
    const clearDriver = () => driverSigCanvas.current!.clear()

    async function takePicture() {
      const image = await Camera.getPhoto({
        quality: 30,
        // source: CameraSource.Prompt,
        allowEditing: false,
        resultType: CameraResultType.Uri
      });
      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      const base64Data = await base64FromPath(image.webPath!);
      setImages(base64Data)
      setImageWebpath(image.webPath!)
      timages.push(image)
      if (timages.length > 0) {
        setPhotoMsg("Take another photo")
      }
    }
    const removePhoto = () => {
      setImages("");
    }

    const save = () => {
        const signed = sigCanvas.current!.toDataURL()
        setSignatueData(signed)
        setShowPassengerSignature(!showPassengerSignature)
    }
    const saveDriver = () => {
        const signed = driverSigCanvas.current!.toDataURL()
        setDriverSignatueData(signed)
        setShowDriverSignature(!showDriverSignature)
    }

    const submit = () => {
        if (images !== "") {
            fillForm()
        } else {
            toastStore.getState().setToast('Please add a photo of the passenger\'s id.');
        }
    }

    async function fillForm() {
      if(driverSignatureData === ""){
          toastStore.getState().setToast('Please save driver signature.');
      } else if (signatureData === ""){
          toastStore.getState().setToast('Please save passenger signature.');
      }
        const formURL = "./assets/passengerForm1.pdf"
        const pdfFormBytes = await fetch(formURL).then(res => res.arrayBuffer())

        const pdfDoc = await PDFDocument.load(pdfFormBytes)
        const form = pdfDoc.getForm()

        const fields = form.getFields()


        fields.forEach(field => {
            const type = field.constructor.name
            const name = field.getName()
            console.log(`${type}: ${name}`)
        })

        var passengerField = form.getTextField('Passenger Name')
        var relationshipField = form.getTextField('Relationship to Passenger')
        var dateField = form.getTextField('Date')
        var nameField = form.getTextField('Name')
        var driverNameField = form.getTextField('Driver Name')
        var truckField = form.getTextField('Truck #')
        var startField = form.getTextField('Start Date')
        var dateOfBirthField = form.getTextField('Date of Birth')
        var governmentIdField = form.getTextField('Passengers Social Security Number')
        var truckAndDriverCodeField = form.getTextField('Truck  and Driver Code')
        var beneficiaryField = form.getTextField('Beneficiary')
        

        passengerField.setText(passenger)
        governmentIdField.setText(governmentId)
        relationshipField.setText(relationship)
        dateField.setText(new Date().toLocaleDateString().slice(0,10))
        nameField.setText(passenger)
        driverNameField.setText(username ?? "none")
        truckField.setText(userInfo.unit)
        startField.setText(new Date().toLocaleDateString().slice(0,10))
        dateOfBirthField.setText(dateOfBirth.slice(0,10))
        truckAndDriverCodeField.setText(userInfo.unit + ' ' + username)
        beneficiaryField.setText(beneficiary)
        const passengerSignatureBytes = await fetch(signatureData).then((res) => res.arrayBuffer())

        const passengerImageSignature = await pdfDoc.embedPng(passengerSignatureBytes)
        const driverSignatureBytes = await fetch(driverSignatureData).then((res) => res.arrayBuffer())
        const driverImageSignature = await pdfDoc.embedPng(driverSignatureBytes)


        const pages = pdfDoc.getPages()
        const firstPage = pages[0]
        const pngDims = passengerImageSignature.scale(0.2)
        firstPage.drawImage(passengerImageSignature, {
            x: firstPage.getWidth() / 2 - pngDims.width / 2 - 75 ,
            y: firstPage.getHeight() / 2 - pngDims.height / 2 - 210,
            width: pngDims.width,
            height: pngDims.height,
          })
        firstPage.drawImage(driverImageSignature, {
            x: firstPage.getWidth() / 2 - pngDims.width / 2 - 100 ,
            y: firstPage.getHeight() / 2 - pngDims.height / 2 - 235,
            width: pngDims.width,
            height: pngDims.height,
          })
        
       
        const usefulData = await pdfDoc.saveAsBase64({dataUri: true})
        setDataURL(usefulData)
        // submitPhoto(images)
        submitForm(usefulData, images)
     
        
    }
    

    return (
        <IonPage>
             <IonModal isOpen={showToast} id="transparent">
                <div className="modal-div">
                    <div id="modal">
                        <div className='loading-div'>
                          <p>Please wait... your form is being submitted.</p>
                          <div className="snippet" data-title=".dot-flashing" style={{ margin: "4px 16px"}}>
                            <div className="stage">
                              <div className="dot-flashing"></div>
                            </div> 
                          </div>
                        </div>
                    </div>
                </div>
            </IonModal>
        
            <IonModal isOpen={showModal}>
                <IonItemGroup style={{ overflowY: "auto"}}>
                  <IonItem color="light" lines="none">
                      <div className="item-container" style={{ display: "flex", alignItems:"center", justifyContent: "space-between"}}>
                        <p>Passenger Authorization Procedures</p>
                        <IonIcon  style={{ fontSize: "large"}} icon={close} onClick={() => setShowModal(false)} />
                      </div>
                  </IonItem>
                  <IonItem lines="none" color="light">
                    <PassengerPolicy></PassengerPolicy>
                  </IonItem>
                  <IonItem lines="none" color="light">
                    <div className="item-container">
                      <button className="western-button submit" onClick={() => setShowModal(false)}>I Understand</button>
                    </div>
                  </IonItem>
                  </IonItemGroup>
            </IonModal>
            <IonHeader >
               <IonToolbar color="primary">
               <IonButtons slot="start">
                   <IonBackButton defaultHref="/Home"  text=""/>
                 </IonButtons>
                 <IonTitle>Passenger Authorization</IonTitle>
               </IonToolbar>
            </IonHeader>
           
            <IonContent fullscreen>
         
                <IonItem color="light" lines="none">
                  <div className="item-container">
                  <p>Application for Passenger Accident Coverage</p>
                  </div>
                </IonItem>
                <IonItem color="light" lines="none">
                  <div className="item-container">
                    <div className="input-container">
                      <input id="name" className="input" type="text" placeholder=" " value={passenger} onChange={e => setPassenger(e.target.value!)}/>
                      <p className="placeholder" >Passenger Name</p>
                    </div>
                  </div>
                </IonItem>
                <IonItem color="light" lines="none">
                  <div className="item-container">
                    <div className="input-container">
                      <input type="date" className="input" value={dateOfBirth} onChange={e => setDateOfBirth(e.target.value!.slice(0,10))}/>
                      <p className="placeholder">Date of Birth</p>
                    </div>
                  </div>
                </IonItem>
                <IonItem color="light" lines="none">
                  <div className="item-container">
                    <div className="input-container">
                      <input id="orientation" className="input" type="number" placeholder=" " value={governmentId} onChange={e => setGovernmentId(e.target.value!)}/>
                      <p className="placeholder" >SSN</p>
                    </div>
                  </div>
                </IonItem>
                <IonItem color="light" lines="none">
                  <div className="item-container">
                    <p>This letter constitutes for authority {passenger === "" ? "___________" : <span style={{ textDecoration: "underline" }}>{passenger}</span>} for to be transported as a non-driving passenger on Unit # <span style={{ textDecoration: "underline" }}>{userInfo.unit}</span> with  <span style={{ textDecoration: "underline" }}>{username}</span> as the ONLY driver.</p>
                    
                    <p>Starting date of <span style={{ textDecoration: "underline" }}>{today.toLocaleDateString().slice(0, 10)}</span> over routes authorized by Western Express until coverage is cancelled due to separation of employment with Western Express or during opt out months DECEMBER AND JUNE ONLY.</p>

                    <p>For my own protection, <span style={{ textDecoration: "underline" }}>{username}</span>, hereby request coverage for the above-named passenger under the Passenger Accident Policy underwritten by National Union Fire Insurance Company of Pittsburg, PA for the period noted above. I authorize Western Express to deduct the premium of $6.00 a week from my payroll.</p>

                    <p>I, {passenger === "" ? "___________" : <span style={{ textDecoration: "underline" }}>{passenger}</span>}, by my signature hereby release and acquit and forever discharge Western Express, Inc, and their agents, representatives and all other persons of any claim, demands, and damages of any kind, known or unknown, resulting in personal injury, death, or property damage arising from any accident or incident while an occupant in any vehicle owned of under contract of Western Express Inc.</p>
                
                    <p>In consideration of Western express Inc. granting me permission for my convenience to travel with <span style={{ textDecoration: "underline" }}>{username}</span> as a non-driving passenger, I assume the risk for any injury or death to myself and do hereby voluntarily and intentionally release, relinquish, waive, renounce, repudiate, and give up on behalf of myself, my estate, and my family and claim or right of action for liability for injury or death against Western Express Inc.</p>
                
                    <p>I HAVE READ THIS GENERAL RELEASE AND WAIVER OF LIABILITY AGREEMENT, AND I DO CONSENT TO THE TERMS STATED ABOVE.</p>

                    <p>I, {passenger === "" ? "___________" : <span style={{ textDecoration: "underline" }}>{passenger}</span>}, hereby request to participate in the Passenger Accident Policy underwritten by National Union Fire Insurance Company of Pittsburg, PA under policy # SRG9126170, and understand and agree that any benefits provided by this policy will be paid directly to my designated beneficiary, if any, or me, or to my estate.</p>
                  </div>
                </IonItem>
        
                <IonItem color="light" lines="none">
                  <div className="item-container">
                    <div className="input-container">
                      <input id="bene" className="input" type="text" placeholder=" " value={beneficiary} onChange={e => setBeneficiary(e.target.value!)}/>
                      <p className="placeholder" >Beneficiary</p>
                    </div>
                  </div>
                </IonItem>
                <IonItem color="light" lines="none">
                  <div className="item-container">
                    <div className="input-container">
                      <input id="bene" className="input" type="text" placeholder=" " value={relationship} onChange={e => setRelationship(e.target.value!)}/>
                      <p className="placeholder" >Relationship to Passenger</p>
                    </div>
                  </div>
                </IonItem>
              
                <IonItemGroup>
                  <IonItem lines="none" color="light">
                    <div className="item-container">
                      <p>Passenger Signature</p>
                      <div hidden={!showPassengerSignature}style={{textAlign: "center", width: "90%", overflow: "hidden", backgroundColor: "white",  margin: "auto", borderRadius: "20px" }}>
                          <SignaturePad
                          ref={sigCanvas}
                          canvasProps={{ width: 500, height: 175, className: 'sigCanvas'}}
                          />
                      </div>
                      <div className="item-container" style={{ display: "flex", justifyContent: "space-between"}}>
                        {showPassengerSignature ? <p onClick={save} style={{ color: "#212121"}}>Save</p> : <button onClick={save} className="button">Re-sign</button>}
                        {showPassengerSignature ? <p style={{color: "#5096FF"}} onClick={clear}>Clear</p>: null}
                      </div>

                    </div>

                  </IonItem>
                  <IonItem lines="none" color="light">
                    <div className="item-container">
                      <p>Driver Signature</p>
                      <div hidden={!showDriverSignature} style={{textAlign: "center", width: "90%", overflow: "hidden", backgroundColor: "white", margin: "auto", borderRadius: "20px" }} >
                        <SignaturePad
                        ref={driverSigCanvas}
                        canvasProps={{ width: 500, height: 175, className: 'sigCanvas'}}
                        />
                    </div>
                    <div className="item-container" style={{ display: "flex", justifyContent: "space-between"}}>
                    {showDriverSignature ? <p onClick={saveDriver} style={{ color: "#212121"}}>Save</p> : <button onClick={saveDriver} className="button">Re-sign</button>}
                      {showDriverSignature ? <p style={{color: "#5096FF"}} onClick={clearDriver}>Clear</p>: null}
                    </div>

                    </div>

                  </IonItem>
                    
                </IonItemGroup>
                <IonItemGroup>
                  <IonItem color="light" lines="none">
                    <div className="item-container">
                      <p>Attach a photo of the passenger's government issued ID before submission</p>
                    </div>

                  </IonItem>

                <IonItem lines="none" color="light">
                  <div className="item-container">
                    <button className='button'  onClick={takePicture}><p>{photoMsg}</p><IonIcon icon={cameraOutline}></IonIcon></button>
                  </div>
                </IonItem>
                <IonItem lines="none" color="light">
                  <div className="item-container">
                    {images !== "" ? <div style={{position: 'relative'}} onClick={() => removePhoto()}>
                      <img alt="breakdown img" src={imageWebpath} style={{width: "100px", borderRadius: "10px"}} />
                      <button style={{ backgroundColor: "rgba(0,0,0,0)", borderRadius: "50%", width: "32px", height: "32px", position: "absolute", top: "0", left: "0"}}><IonIcon style={{color: "#ff0000", fontSize: "large"}} icon={trashOutline}></IonIcon></button>
                    </div> : ""}
                  </div>
                </IonItem>
              </IonItemGroup>
                <IonItemGroup>
                <IonItem lines="none" color="light">
            <div className='item-container'>
              <div className="space-between">
                  <p style={{ width: "80%"}} className="subtitle">Legal Agreement. I approve the above information as complete and correct and certify that I am the driver identified above. I further acknowledge that and consent to the use of this submission as an electronic signature in accordance with 49 CFR § 390.32 and 15.U.S.C. § 7001(c).</p>
                  <IonIcon icon={alertCircleOutline} style={{ color: "#ff0000", fontSize: "large"}}></IonIcon>
              </div>
            </div>
          </IonItem>
           <IonItem lines="none" color="light">
               <div className="item-container">
                   <button className="western-button submit" onClick={() => {
                       if (driverSignatureData === "") {
                           toastStore.getState().setToast('Please save driver signature.');
                       } else if (signatureData === "") {
                           toastStore.getState().setToast('Please save passenger signature.');
                       } else if (images === "") {
                           toastStore.getState().setToast('Please save passenger photo.');
                       } else if (passenger === "" || dateOfBirth === "" || governmentId === "" || beneficiary === "" || relationship === "") {
                           toastStore.getState().setToast('Please complete all fields.');
                       } else {
                           submit();
                           setShowToast(true);
                       }
                   }}>
                       <div id="btn-text" onClick={e => handleEventLog("passenger_Auth", "submit-PassengerForm")}>Submit</div>
                   </button>
               </div>
           </IonItem>
                </IonItemGroup>
            </IonContent>
           

       
        </IonPage>
    )

}

export default PassengerForm;
