import {IonItem, IonSlide, IonSlides} from '@ionic/react'
import React, {useEffect, useRef, useState} from 'react'
import leaderboardIcon from "../imgs/lb_load_bars.gif"
import CountUp from 'react-countup';
import {Link} from "react-router-dom";
import {ApiService} from "../api/ApiService";
import {GamificationDriver2} from "../api/model/GamificationDriver2";
import {userStore} from "../api/AuthService";


const Game = () => {
    const [gamify, setGamify] = useState(0)
    const [gamifyType, setGamifyType] = useState('miles')
    const [noGameData, setNoGameData] = useState(false)
    const [gameData, setGameData] = useState({
        COST_CENTER: "-",
        HIRE_DATE: "-",
        HIRE_TENURE_DAYS: 0,
        MILES_LIFETIME: 0,
        MILES_MTD: 0,
        MILES_MTD_RANK: 0,
        MILES_WTD: 0,
        MILES_WTD_RANK: 0,
        MPG_MTD_COSTCENTER_GROUP: "-",
        MPG_MTD_MILES: 0,
        MPG_MTD_MPG: 0,
        MPG_MTD_RANK: 0,
        MPG_MTD_TRUCK_GROUP: "-",
        MPG_WTD_COSTCENTER_GROUP: "-",
        MPG_WTD_MILES: 0,
        MPG_WTD_MPG: 0,
        MPG_WTD_RANK: 0,
        MPG_WTD_TRUCK_GROUP: "-",
        MTD_TEAM: 0,
        PRETRIPS_MTD_RANK: 0,
        PRETRIPS_WTD_RANK: 0,
        WTD_TEAM: 0,
        DIVISION: "-",
        exectime: 0  
      }
    )
      const [weekMonthAllTime, setWeekMonthAllTime] = useState([{miles: 0, loads: 0}])

    useEffect(() => {
        const interval = setInterval(() => {
            setGamifyType(gamifyType === 'miles' ? 'mpg' : 'miles');
            handleSlideChange();
        }, 6500);
        ApiService.getGamificationDriver2("game")
            .then(it => createGameObject(it))
            .catch(() => setNoGameData(true));
        return () => clearInterval(interval);
    }, []);

    let select = document.querySelector("select");
    let options = select?.options
    function toggleMilesLoads () {
      if(options) {
        switch (gamify) {
          case 0:
            setGamify(1)
            options[1].selected = true
            break
          case 1:
            setGamify(2)
            options[2].selected = true
            break
          case 2:
            setGamify(0)
            options[0].selected = true
            break
          default:
            console.log('nomore')
        }
      }
    }
    const mySlides = useRef<HTMLIonSlidesElement>(null)
    const slideOpts = {
      initialSlide: 0,
      speed: 400,
      loop: true,
      // autoplay: 5000
    }
    const handleSlideChange = async () => mySlides.current?.slideNext()

    function createGameObject(data: GamificationDriver2) {
        var newGameObject = weekMonthAllTime
        newGameObject[0] = {
            miles: data.MILES_WTD,
            loads: data.LOADCT_WTD
        };
        newGameObject[1] = {
            miles: data.MILES_MTD,
            loads: data.LOADCT_MTD
        };
        newGameObject[2] = {
            miles: data.MILES_LIFETIME,
            loads: data.LOADCT_LIFE
        };
        setWeekMonthAllTime(newGameObject);
        setGameData(data as any);
    }

      const countup = (number: number) => {
        return (
          <CountUp end={number} duration={1.0} delay={0.5} separator=','/>
        )
      }
      const ordinateNumber = (number: number) => {
        var j = number % 10,
            k = number % 100;
        if (j === 1 && k !== 11) {
            return "st";
        }
        if (j === 2 && k !== 12) {
            return "nd";
        }
        if (j === 3 && k !== 13) {
            return "rd";
        }
        return "th";
    
      }
    
    return (
        <IonItem color="light" lines="none">
            {noGameData ?  <div className='item-container'>
                  <div className='western-button-link'>
                    <div>
                      <p style={{ textAlign: "center", margin: 0}}>You don't appear to have anything to show. Contact your DM to see how you can start earning points. Still having issues, send a message to <a href="mailto:driverappfeedback@westernexp.com">driverappfeedback@westernexp.com</a></p>
                    </div>
                  </div>
                  <div className="grid50">
                    <Link className='western-button-link' to="/rewards">
                      <div id="centered" style={{ minWidth: "10vw"}}>
                        <p>Leaderboard</p>
                        
                        <strong><img  style={{ marginRight: "8px"}} src={leaderboardIcon} alt="leaderboard"/>-</strong>
                        <br/><span id="more-info-gold">Go to Leaderboard</span>
                      </div>
                    </Link>
                    <Link className='western-button-link' to="/Division">
                      <div id="centered" style={{ minWidth: "10vw"}}>
                        <p>Division</p>
                        <strong>{userStore.getState().driver?.division}</strong>
                        <br/><span id="more-info-grey">More Info</span>   
                      </div>
                    </Link>
                  </div>
                </div> : <div className='item-container'>
                <div className="grid50">
                    <div>
                        <IonSlides
                        options={slideOpts}
                        ref={mySlides}
                        >
                        <IonSlide>
                            <Link to="/rewards" className='western-button-link-slide'>
                         
                                <p >Miles Leaders</p>
                                
                                <strong><img  style={{ marginRight: "8px"}} src={leaderboardIcon} alt="leaderboard"/>{gameData.MILES_WTD_RANK ? gameData.MILES_WTD_RANK : '-'}<sup>{gameData.MILES_WTD_RANK ? ordinateNumber(gameData.MILES_WTD_RANK) : ''}</sup></strong>  
                                <br/><span id="more-info-gold">Go to Leaderboard</span>
                       
                            </Link>
                        </IonSlide>
                        <IonSlide>
                            <Link to="/rewards" className='western-button-link-slide'>
                       
                                <p >MPG Leaders</p>
                                
                                <strong><img  style={{ marginRight: "8px"}} src={leaderboardIcon} alt="leaderboard"/>{gameData.MPG_WTD_RANK ? gameData.MPG_WTD_RANK : '-'}<sup>{gameData.MPG_WTD_RANK ? ordinateNumber(gameData.MPG_WTD_RANK) : ''}</sup></strong>  
                                <br/><span id="more-info-gold">Go to Leaderboard</span>
                    
                            </Link>
                        </IonSlide>
                        <IonSlide>
                            <Link to="/rewards" className='western-button-link-slide'>
                          
                                <p >Pretrip Leaders</p>
                                
                                <strong><img  style={{ marginRight: "8px"}} src={leaderboardIcon} alt="leaderboard"/>{gameData.PRETRIPS_WTD_RANK ? gameData.PRETRIPS_WTD_RANK : '-'}<sup>{gameData.PRETRIPS_WTD_RANK ? ordinateNumber(gameData.PRETRIPS_WTD_RANK) : ''}</sup></strong>  
                                <br/><span id="more-info-gold">Go to Leaderboard</span>
                 
                            </Link>
                        </IonSlide>
                        </IonSlides>
                    </div>
                    <Link className='western-button-link' to="/Division">
                        <div id="centered" style={{ minWidth: "10vw"}}>
                        <p>Division</p>
                        <strong>{gameData.DIVISION}</strong>
                        <br/><span id="more-info-grey">More Info</span>
                        </div>
                    </Link>
                    </div>
                    <div className='item-container'>
                    <select className="input" onChange={(e) => {setGamify(parseInt(e.target.value))}} style={{ height: "20px", width: "auto", textAlign: "center"}}>
                        <option value={0}>Week</option>
                        <option value={1}>Month</option>
                        <option value={2}>All Time</option>
                    </select>
                    </div>
                    <div style={{ display: 'grid', gridTemplateColumns: '35% 60%', gridGap: "5%", margin: '8px'}}>
                    <div id="gamify-card" onClick={() => toggleMilesLoads()}>
                        <p>Loads</p>
                        <strong>{countup(weekMonthAllTime[gamify].loads)}</strong>
                    </div>
                    <div id="gamify-card" onClick={() => toggleMilesLoads()}>
                        <p>Miles</p>
                        <strong>{countup(weekMonthAllTime[gamify].miles)}</strong>  
                    </div>
                </div>

            </div>}
        </IonItem>
    )
}

export default Game
