import React, {useState} from "react";
import {IonIcon, IonItem} from "@ionic/react";
import {calendarClearOutline} from "ionicons/icons";
import {ApiService} from "../api/ApiService";
import {TrainingReportGet2} from "../api/model/TrainingReportGet2";

export interface Report {
    trainerCode: string,
    roadTest: any,
    courseId: number,
    courseDay: number,
    backingScore: number,
    turningScore: number,
    roadHabitsScore: number,
    cityScore: number,
    highwayScore: number,
    tabletScore: number,
    hosScore: number,
    defensiveScore: number,
    overallScore: number,
    dmCode: string,
}

const ReportDetail = (props: any) => {
    const [report, setReport] = useState<Report>({
        trainerCode: '',
        roadTest: '',
        courseId: 0,
        courseDay: 0,
        backingScore: 0,
        turningScore: 0,
        roadHabitsScore: 0,
        cityScore: 0,
        highwayScore: 0,
        tabletScore: 0,
        hosScore: 0,
        defensiveScore: 0,
        overallScore: 0,
        dmCode: ''
      })
      const [showReport, setShowReport] = useState(false)

    function getReportDetails() {
        ApiService.getTrainingReport2("reportDetail", {traineeCode: props.trainee, timeSubmitted: props.date}).then(it => {
            parseReport(it);
        });
    }

    function parseReport(reportObj: TrainingReportGet2) {
        let tempObj: any = reportObj
        console.log("tempObj", tempObj);
        let tempReportData: any = JSON.parse(reportObj.reportData)
        Object.keys(tempReportData).forEach((element: any) => {
            tempObj[element] = tempReportData[element]
        });
        console.log('traineeObj', tempObj);
        setReport(tempObj)
        setShowReport(true);
    }

    return (
        <IonItem lines="none" color="white">
        <div className="item-container">
            <div style={{ backgroundColor: "#eee", padding: "0 8px", borderRadius: "10px", width: "100%", display: "flex", justifyContent: "space-between"}} onClick={ () => getReportDetails()} >
            <p style={{ color: "#D0A024" }}>{showReport ? "Viewing Report" :  "View Report"}</p><p style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", width: "50%"}}>{props.date}<IonIcon icon={calendarClearOutline}></IonIcon></p>
            </div>
            {showReport ? <div>               
                <p>Trainer: {report.trainerCode}</p>
            
                <p>DM: {report.dmCode !== "null" ? report.dmCode :  "-"}</p>
            
                <p>Road Test: {report.roadTest !== 0 ? 'Yes' : 'No'}</p>

                <p>Backing Score: {report.backingScore}</p>
            
                <p>Turning Score: {report.turningScore}</p>
            
                <p>Road Behavior: {report.roadHabitsScore}</p>
            
                <p>City Driving Score: {report.cityScore}</p>
            
                <p>Highway Driving Score: {report.highwayScore}</p>
            
                <p>Tablet Score: {report.tabletScore}</p>
            
                <p>HOS Score: {report.hosScore}</p>
            
                <p>Defensive Driving Score: {report.defensiveScore}</p>
            
                <p>Overall Report Score: {report.overallScore}/40</p>
            </div> :null}
        </div>
    </IonItem>
    )
}

export default ReportDetail
