export enum ApiCommand {
    DRIVER_PROFILE = 'driverProfile',
    LOAD_DETAILS = 'loadDetails',
    LOAD_HISTORY = 'loadHistory',
    PHOTO_LIST = 'photoList',
    TRAINEE_SEARCH = 'traineeSearch',
    TRAINEE_PROFILE = 'traineeProfile',
    TRAINEE_COURSE_ASSIGN = 'traineeCourseAssign',
    TRAINEE_RELEASE = 'traineeRelease',
    TRAINEE_CLAIM = 'traineeClaim',
    TRAINING_REPORTS_GET = 'trainingReportsGet',
    TRAINING_REPORTS_GET_2 = 'trainingReportsGet2',
    TRAINING_REPORT_GET_2 = 'trainingReportGet2',
    TRAINING_REPORT_BACKING = 'trainingReportBacking',
    TRAINING_REPORT_SUBMIT_2 = 'trainingReportSubmit2',
    TRAINING_REPORT_DAY_PASSED_2 = 'trainingReportDayPassed2',
    TRAINING_COURSE_GET = 'trainingCourseGet',
    TRAINING_COURSES_GET = 'trainingCoursesGet',
    TRAINING_PROMPTS_GET = 'trainingPromptsGet',
    TRAINER_FEEDBACK = 'trainerFeedback',
    TRAINER_AGREEMENT = 'trainerAgreement',
    DRIVER_SSN = 'driverSSN',
    DOCS_LIST = 'docsList',
    GENERIC_EMAIL = 'genericEmail',
    GENERIC_FILE_EMAIL = 'genericFileEmail2',
    LARED_INSPECTION_SUBMIT = 'laredoInspectionSubmit',
    USER_LOGOUT = 'userLogout',
    LEADERBOARD = 'leaderboard2',
    USER_GET = 'userGet',
    USER_STATE_GET = 'userStateGet',
    REGISTER_DEVICE_TOKEN = 'registerDeviceToken',
    PUSH_NOTIFICATIONS_GET = 'pushNotificationsGet',
    PUSH_NOTIFICATION_READ = 'pushNotificationRead',
    PREPLANNED_LOADS_GET = 'preplannedLoadsGet',
    VIDEOS_GET = 'videosGet',
    CONTACTS_GET = 'contactsGet',
    DM_GET = 'dmGet',
    DM_DRIVERS_GET = 'dmDriversGet',
    LOGIN_AS_DRIVER = 'loginAsDriver',
    ROAD_TEST_SUBMIT = 'roadTestSubmit',
    VEHICLE_REGISTER = 'vehicleRegister',
    PAYROLL_OVERVIEW = 'payrollOverview',
    GAMIFICATION_DRIVER_2 = 'gamificationDriver2',
    INBOX_SEND_MESSAGE = 'inboxSendMessage',
    INSPECTION_SUBMISSION = 'inspectionSubmission',
    CASH_ADVANCE = 'cashAdvance',
    CASH_ADVANCES_GET = 'cashAdvancesGet',
    CASH_ADVANCE_ELIGIBILITY = 'cashAdvanceEligibility',
    PAYCHECK_HISTORY = 'paycheckHistory',
    PAYCHECK_DETAIL = 'paycheckDetail',
    ORIENTATION_UPDATE = 'orientationUpdate',
    ORIENTATION_EVAL_SUBMIT = 'orientationEvalSubmit',
    ORIENTATION_DRIVER_LIST = 'orientationDriverList',
    TENSTREET_DOCUMENT = 'tenstreetDocument',
    NEWS_ARTICLES_GET = 'newsArticlesGet',
    INBOX_GET_ALL_MESSAGES = 'inboxGetAllMessages',
    HELP_REQUEST = 'helpRequest',
    FAQ_ENTRIES_GET = 'faqEntriesGet',
    PHOTO_SUBMISSION = 'photoSubmission',
    TRAILER_REJECT = 'trailerReject',
    DRIVER_PROFILE_UPDATE = 'driverProfileUpdate',
    LP_INSPECTION_SUBMISSION = 'lpInspectionSubmission',
    GENERIC_REPORT_SUBMISSION = 'genericReportSubmission',
    TRAILER_LOCATION = 'trailerLocation',
    TRAILER_CONNECT = 'trailerConnect',
    USER_CREATE = 'userCreate',
    USER_UPDATE = 'userUpdate',
    USER_LOGIN = 'userLogin',
    USER_GET_RESET_TOKEN = 'userGetResetToken',
    USER_RESET_TOKEN_VERIFY = 'userResetTokenVerify',
    USER_RESET_PASSWORD = 'userResetPassword',
    PHOTO_CHECKSUM = 'photoChecksum',
    PHOTO_CHECKSUM_REVOKE = 'photoChecksumRevoke',
    SAFETY_SCORECARD = 'safetyScorecard',
    YD_VEHICLES_BY_TERMINAL = 'ydVehiclesByTerminal',
    YD_INSERT = 'ydInsert',
    YD_LATEST = 'ydLatest',
}
