import {IonContent, IonFooter, IonHeader, IonItem, IonList, IonPage, IonToolbar} from '@ionic/react';
import React, {useEffect, useState} from 'react';
import NotificationCard from './NotificationCard'
import {config} from '../Constants'


//Google Analytics:
import {getAnalytics, logEvent} from 'firebase/analytics';
import {initializeApp} from "firebase/app";
import Nav from './Nav';
import Toolbar from './Toolbar';
import Search from './Search';
import {ApiService} from "../api/ApiService";
import {PushNotification} from "../api/model/PushNotification";
import {useHistory} from "react-router-dom";

function NotificationHub(props: any) {

    const history = useHistory();

    const [isSearching, setIsSearching] = useState(false)
    const [notificationArray, setNotificationArray] = useState<PushNotification[]>([])
    function handleSearch(value: string) {
        console.log(value)
    }
    function handleMode (value: boolean) {
         // setIsSearching(value)
        history.push('/home?search=true');
    }
    

    const firebaseConfig = {
        apiKey: "AIzaSyDE8xEN_qtMPO-aBkTon7S7kKcyUZJD5gc",
        authDomain: "western-express-5002c.firebaseapp.com",
        databaseURL: "https://western-express-5002c.firebaseio.com",
        projectId: "western-express-5002c",
        storageBucket: "western-express-5002c.appspot.com",
        messagingSenderId: "799211811572",
        appId: "1:799211811572:web:34706f63a8621cb0b0a4a1",
        measurementId: "G-V5MNJV768B"
    }
     
    const app = initializeApp(firebaseConfig)
     
    const handleEventLog = (eventType: string, info: string) => {
        const analytics = getAnalytics(app);
        logEvent(analytics , eventType, {eventType: info})
        console.log(eventType);
    }

    useEffect(() => {
        ApiService.sendEventCommand("Notifications", "eventCheck", "User viewed their notifications");
        ApiService.getPushNotifications("notificationHub").then(it => setNotificationArray(it));
    }, []);

    const [height1, setHeight1] = useState(0);
        
    const toggle = () => {
        // console.log("toggle");
        if (height1 === 0) {setHeight1(1);}
        else {setHeight1(0)}
    }

    const abridge = (post: string) => {
        return post.slice(0, 75) + "..."
    }

    function sendRead(id: number) {
        ApiService.readPushNotification("notificationHub", {id}).then();
    }

    function renderSidebar() {

        return <div className="sidebar-notifications" onClick={e => e.stopPropagation()}>
                <div className={config.isPlatformSci ? "sidebar-menu-area-plat-sci" : "sidebar-menu-area"}>
                    { notificationArray.length !== 0 ? notificationArray.length !== 1 ?
                        notificationArray.map(function(element: any){
                            // console.log('element', element);
                            return <NotificationCard notificationElement={element} readStatus={sendRead}/>
                        }) :
                        <div className={notificationArray[0].read !== 1 ? "notification-card" : "notification-card-read"} onClick={e => {toggle(); sendRead(notificationArray[0].id)}}>
                            <div className="post-title" style={{width: "100%"}}>
                                <h6>{notificationArray[0].sent.replace('T', ' ').slice(0,-14).substr(5) + "-" + notificationArray[0].sent.substr(0, 4)}</h6>
                            </div>
                            <p className="post-body">{ height1 !== 1 ? abridge(notificationArray[0].message) : notificationArray[0].message }</p>
                        </div>
                    :
                    <div className="notification-card-read">
                        <div style={{width: "100%", textAlign: "center"}}>
                            <h5><b>No Notifications</b></h5>
                        </div>
                    </div>
                    }
                </div>
            </div>
        }

    return (
        <IonPage>
        <IonHeader>
            <IonToolbar color="primary">
                <Toolbar/>
            </IonToolbar>
        </IonHeader>
        <IonContent fullscreen color="light">
            {isSearching ? <Search onChange={handleSearch} featured={'Lets take a look'}/> : null}
            <IonItem lines="none" color="light"><div className="item-container"><h3><strong>Notifications</strong></h3></div></IonItem> 
            <IonList lines="none" color="light" style={{ padding: 0}}>
              
                <IonItem color="light" lines="none">
                    <div className="item-container">
                    { notificationArray.length !== 0 ? notificationArray.length !== 1 ?
                        notificationArray.map(function(element: any){
                            // console.log('element', element);
                            return <NotificationCard notificationElement={element} readStatus={sendRead}/>
                        }) :
                        <div className={notificationArray[0].read !== 1 ? "notification-card" : "notification-card-read"} onClick={e => {toggle(); sendRead(notificationArray[0].id)}}>
                            <div className="post-title" style={{width: "100%"}}>
                                <h6>{notificationArray[0].sent.replace('T', ' ').slice(0,-14).substr(5) + "-" + notificationArray[0].sent.substr(0, 4)}</h6>
                            </div>
                            <p className="post-body">{ height1 !== 1 ? abridge(notificationArray[0].message) : notificationArray[0].message }</p>
                        </div>
                    :
                    <div className="notification-card-read">
                        <div style={{width: "100%", textAlign: "center"}}>
                            <h5><b>No Notifications</b></h5>
                        </div>
                    </div>
                    }
                    </div>
                </IonItem>
            </IonList>
        </IonContent>
        <IonFooter>
            <Nav onSearch={handleMode}/>
        </IonFooter>
    </IonPage>
    );

};
export default NotificationHub;
