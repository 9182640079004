import React, { useRef, useState, useEffect } from 'react'
import { IonButton, IonContent, IonHeader, IonIcon, IonItem, IonPage, IonSlide, IonSlides, IonToolbar} from '@ionic/react'
import GuideSlide from './GuideSlide'
import appPhoneIcon from '../imgs/phone.gif'
import load1Img from '../imgs/load1.png'
import load2Img from '../imgs/load2.png'
import load3Img from '../imgs/load3.png'
import { informationCircleOutline, warning, warningOutline } from 'ionicons/icons'
import pretripIcon from "../imgs/pretrip.png"
import passengerIcon from "../imgs/add-passenger.png"
import searchIcon from "../imgs/search.png"
import searchNavIcon from "../imgs/search-nav.png"
import searchingGif from "../imgs/search.gif"
import searchPay from "../imgs/search-pay.png"
import { config } from '../Constants'
import {Link, useHistory} from "react-router-dom";
import { ApiService } from '../api/ApiService'


const Guide = () => {

    const history = useHistory();

    const [index, setIndex] = useState(0)

    const mySlides = useRef<HTMLIonSlidesElement>(null)
    const slideOpts = {
      initialSlide: 0,
      speed: 400,
      loop: true
      // autoplay: 5000
    }

    useEffect(() => {
        ApiService.sendEventCommand("Viewed Guide", "eventCheck", "User has viewed the Mobile Experience Learning Center");
      }, []);

    const handleSlideChange = (slide: number) => { let slides = document.getElementById("slides") as HTMLIonSlidesElement; slides.slideTo(slide)}

    function homePageContent () {
        return <div>
            <p>Before you can use the app, you need to be able to login 😘</p>
                        <button className='western-button' onClick={() => handleSlideChange(2)}>How to reset your password?</button>
                        <p>Or skip this step, and learn more about using the app.</p>
                        <a style={{ fontSize: "large"}} onClick={() => handleSlideChange(3)}>I know my password. Skip this step.</a>
        <p className='subtitle' style={{ fontSize: "x-small", textAlign: "center", color: "#D0A024"}}>Swipe Right or Left to navigate freely through the guide.</p>
        </div>
    }
    const homePageOptions = {
        title: "Staying Mobile, When You Are.",
        subtitle: "Welcome to the Western Express Driver App!",
        img: appPhoneIcon,
        imgText: "Every Western Express driver has access to these easy-to-use features, helping them on their journey to success, while giving them the tools to manage their own experience.",
        content: homePageContent()
    }
    
    function searchPageContent () {
        return <div>
            <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", gap: "4px"}}>
            <p>Start by selecting the search icon </p> <div style={{backgroundColor: "#212121", padding: "4px", height: "min(8vw, 28px)", width: "min(8vw, 28px)", borderRadius: "3px"}}>
                    <img src={searchNavIcon} alt="search" style={{ height: "min(5vw, 22px)"}}/>
                  
                </div>
          
              
          
            </div>
            <p className='subtitle'>Search by keywords or phrases.</p>
            <img src={searchingGif}  alt="searching"/>
            <p className='subtitle'>Results</p>
            <div className='more-info-image' style={{ maxWidth: "200px"}}>
                <img src={searchPay} alt="search-pay"/>
            </div>
            <p className='subtitle' style={{ fontSize: "x-small", textAlign: "center", color: "#D0A024"}}>Swipe Right or Left to navigate freely through the guide.</p>

           
            
        </div>
    }
    const searchPageOptions = {
        title: "Can't find what you need?",
        subtitle: "Search any feature, and the relevant sections of the app will appear.",
        img: searchIcon,
        imgText: "A good way to learn where features are in the app— is to search them!",
        content: searchPageContent()
    }
     function resetPageContent () {
        return <div>
            <p>If you're already logged out:</p>
            <p style={{ fontSize: "small", color: "#666667", paddingLeft: "16px"}}>1. From the login screen, select "Forgot Password?"</p>
            <p style={{ fontSize: "small", color: "#666667", paddingLeft: "16px"}}>{`2. Type in your driver code (${localStorage.getItem('username')}), where it says username.`}</p>
            <p style={{ fontSize: "small", color: "#666667", paddingLeft: "16px"}}>3. Submit.</p>
            <p style={{ fontSize: "small", color: "#666667", paddingLeft: "16px"}}>4. You will be asked for a code sent by email or text, which will open a password reset form.</p>
            <p>Not logged out? Logout first to reset your password</p>
            <p style={{ fontSize: "small", color: "#666667", paddingLeft: "16px"}}>1a. Look for the gear icon (Settings) in the top right.</p>
            <p style={{ fontSize: "small", color: "#666667", paddingLeft: "16px"}}>2a. In the settings screen, select "Logout"</p>
            <p style={{ fontSize: "small", color: "#666667", paddingLeft: "16px"}}>3a. Follow steps 1 - 3.</p>
                    <p className='subtitle' style={{ fontSize: "x-small", textAlign: "center", color: "#D0A024"}}>Swipe Right or Left to navigate freely through the guide.</p>
            

        </div>
    }
    const pretripPageOptions = {
        title: "Before you set off",
        subtitle: "There are a few important steps to complete before you begin your trip.",
        content: pretripPageContent()
    }
     function pretripPageContent () {
        return <div>
            <p>Here's your trip checklist:</p>
            <p style={{ fontSize: "small", color: "#666667", paddingLeft: "16px"}}>1. Complete your Pre-trip inspection form in the Trip tab. Select "Pretrip."</p>
             {config.isPlatformSci ? <div className='item-container'>
              <div>
                  <p className="subtitle"  style={{ color: "#ffc409"}}>  <IonIcon icon={warningOutline}></IonIcon> Pre-trip is unavailable from the app for driver's in Platform Science trucks. If you have a Platform Science device mounted in your truck: Pre-trip must by completed on the tablet, NOT in the Western Express Mobile App.</p>
              </div>
            </div>:
            <Link to="/pretrip" className='western-button-link'>
                <div>
                    <img src={pretripIcon} alt="pretrip" style={{ maxHeight: "22px", margin: "4px"}}/>
                    <p>Pretrip</p>
                </div>
            </Link>}
            <p style={{ fontSize: "small", color: "#666667", paddingLeft: "16px"}}>2. If your load requires tarp protection. Submit tarp photos by selecting "Tarp Photo" in the Trip tab.</p>
             <Link to="/photoupload" className="image-button">
                <div id="last-tarp">
                    <p>Tarp Photo</p>
                </div>
            </Link>
            <p style={{ fontSize: "small", color: "#666667", paddingLeft: "16px"}}>3. If you have another passenger with you, please select "Add Passenger" in the Trip tab, and complete the form.</p>
              <Link className="western-button-link" to="/passenger">
                        <div>
                        <img src={passengerIcon} alt="key" style={{ maxHeight: "22px", margin: "4px"}}/><p>Add Passenger</p>
                        </div>
                    </Link>
            <a style={{ fontSize: "large"}} onClick={() => handleSlideChange(4)}>Learn about preparing for a delivery.</a>
            <p className='subtitle' style={{ fontSize: "x-small", textAlign: "center", color: "#D0A024"}}>Swipe Right or Left to navigate freely through the guide.</p>


        </div>
    }
    const resetPageOptions = {
        title: "How do I reset my password?",
        subtitle: "Follow these steps to quickly reset your password",
        content: resetPageContent()
    }
      const loadDetailsScreenshots = [
        {
            img: load1Img,
            info: "Load Number and Trailer identify the delivery. A dispatch code is included with every shipment and the current status of the load."
        }, 
        {
            img: load2Img,
            info: "What is your trip going to look like? See a breakdown of your miles on your route, and what type of load/unload process is expected upon delivery. Be sure to tarp your load properly(if its required), take your photos, and get paid!"
        },
        {
            img: load3Img,
            info: "Get information on the shipper and consignee. Need to show PO or pickup number when you arrive? Be sure to arrive in your pickup window, seen above the customer name."
        },

    ]
    const loadPageImages = loadDetailsScreenshots.map((image,index) => {
        return (
            <div className="more-info-image" key={index}>
                <img src={image.img} alt ="load-img"/>
                <p><IonIcon icon={informationCircleOutline}></IonIcon>{image.info}</p>
                
            </div>
        )
    })
     function loadPageContent () {
        return <div>
            <p>Hold down on each section for more info.</p>
            {loadPageImages}
                   <p className='subtitle' style={{ fontSize: "x-small", textAlign: "center", color: "#D0A024"}}>Swipe Right or Left to navigate freely through the guide.</p>


        </div>
    }
    const loadPageOptions = {
        title: "Where do I find load details?",
        subtitle: "If you're assigned a load, access the delivery details from the Home page, or the Load tab. Select 'Current Load'",
        content: loadPageContent()
    }
  
  
    const slides = [
        <GuideSlide
            options={homePageOptions}
            next={nextPage}
            previous={previousPage}
        />,
        <GuideSlide
            options={resetPageOptions}
            next={nextPage}
            previous={previousPage}
        />,
         <GuideSlide
            options={pretripPageOptions}
            next={nextPage}
            previous={previousPage}
        />,
        <GuideSlide
            options={loadPageOptions}
            next={nextPage}
            previous={previousPage}
        />,
         <GuideSlide
            options={searchPageOptions}
            next={nextPage}
            previous={previousPage}
        />
    ]
      const getSlides = slides.map((slide,index) => {
        return <IonSlide key={index}>{slide}</IonSlide>
    })
    
    function nextPage () {
        let tmp = index
        if(tmp < slides.length) {
            tmp++
            setIndex(tmp)
        }
    }
    function previousPage () {
        let tmp = index
        if(tmp >= 1) {
            tmp--
            setIndex(tmp)
        }
    }
    return (
        <IonPage>
             <IonHeader>
          <IonToolbar color="primary">
            <IonButton slot="start" style={{padding: 0}}><p style={{fontFamily: "Montserrat, sans-seriff"}} onClick={() => history.push('/settings')}>Back</p></IonButton>
          </IonToolbar>
        </IonHeader>
            <IonContent fullscreen color="light">
                <IonSlides id="slides" options={slideOpts} ref={mySlides}>
                    {getSlides}
                </IonSlides>
            </IonContent>
        </IonPage>
    )
}

export default Guide
