// export const config = {
//     url: {
//         BASE_URL: 'http://mobiledevserver.westernexp.com/v3',
//         BASE_URL_PHOTOS: 'http://mobiledevserver.westernexp.com/upload/v3',
//         BASE_URL_DM_PHOTOS: 'http://mobiledevserver.westernexp.com:1976/DM-Photos'
//     },
//     //Set true if build is for PlatSci APK build and release
//     isPlatformSci: false,
//     discordErrorHandlerEnabled: false,
//     sessionTimerEnabled: false,
// };

export const config = {
   url: {
       BASE_URL: 'https://westernexpressmobile.com/wemby3',
       BASE_URL_PHOTOS: 'https://westernexpressmobile.com/php/u.php',
       BASE_URL_DM_PHOTOS: 'https://westernexpressmobile.com/DM-Photos'
   },
   //Set true if build is for PlatSci APK build and release 
   isPlatformSci: false,
   discordErrorHandlerEnabled: true,
   sessionTimerEnabled: false,
}

export const version = "3.8.2.0"
