import React, { useEffect, useState } from "react"
import { calendarClearOutline, chevronBack, chevronDown } from 'ionicons/icons';
import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import newstest from '../imgs/newsTest.jpg'
import {config} from '../Constants'
import { setDashPattern } from "pdf-lib";
// import { coreValueOfTheDay } from "../Util";
interface Photo {
    photoURL: string;
  }

function NewsPost(props: any) {
    useEffect(() => {
        console.log(props.article)
    }, [])
 

    function abridge (post: string) {
        return post.slice(0, 100) + "..."
    }
    const formatDate = (date: string) => {
        if(date) {
            let dateObj = new Date(date).getTime() + new Date().getTimezoneOffset() * 60000
            return new Date(dateObj).toLocaleString().split(',')[0]
        }
    }

    return (
        <div className="news-post">
        <div style={{ backgroundImage: `url(${props.photo})`, height: "max(25vh, 200px)", width: '100%', backgroundSize: "cover"}}>
           <IonIcon icon={chevronBack} style={{ fontSize: "max(32px, 3vw)", margin: "16px"}} onClick={() => props.close(false)}></IonIcon>
        </div>
        <IonItem lines="none" color="light">
            <div className="item-container">
                <h5 style={{ fontWeight: '600', width: "80%"}}>{props.article.title}<span><strong style={{ color: "#666667", margin: ' 0 8px', fontSize: "12px"}}>{formatDate(props.article.date)}</strong></span></h5>
                <p>{props.article.article}</p>
            </div>
        </IonItem>
        </div>
    )
}

export default NewsPost