import React, { useEffect, useState } from "react"
import { IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonPage, IonToolbar } from "@ionic/react";
import {userStore} from "../api/AuthService";
import { ApiService } from "../api/ApiService";
// import { coreValueOfTheDay } from "../Util";


function DivisionInfo(props: any) {
    const [divInfo, setDivInfo] = useState<any>({});
    const [noDiv, setNoDiv] = useState<boolean>(false);
    const [additionalInfo, setAdditionalInfo] = useState(false)



    const FBOTR500 = {
        "info": ["14-16 days out/Home 2-3", "$25 tarp pay", "Potential to run 2,500+ miles per week", "Potential to run all 48 States"],
        // "columns": [
        // {"name": "Miles"},
        // {"name": "Loads"},
        // {"name": "Minimum Pay"}],
        // "rows": [
        //     ["1700", "6", "$1500", "$0.88"],
        //     ["1600", "5", "$1200", "$0.75"],
        //     ["1500", "4", "$1000", "$0.67"]
        // ],
        // "overbalance": "**Every mile over 1600 paid at the greater of $0.50 or their tenure rate"
    }
    const EastCoastVan100 = {
        "info": ["10-12 days out/ home 2-3", "Potential to run 2,500+ miles per week", "Potential to run everything east of the MS river"],
        // "columns": [
        // {"name": "Miles"},
        // {"name": "Loads"},
        // {"name": "Minimum Pay"}],
        // "rows": [
        //     ["1700", "6", "$1500", "$0.88"],
        //     ["1600", "5", "$1200", "$0.75"],
        //     ["1500", "4", "$1000", "$0.67"]
        // ],
        // "overbalance": "**Every mile over 1600 paid at the greater of $0.50 or their tenure rate"
    }
    const WestCoastVan800 = {
        "info": ["2 weeks out/Home 2-3 days", "Potential to run 2,500+ miles per week", "Potential to run Western 11 states"],
        // "columns": [
        // {"name": "Miles"},
        // {"name": "Loads"},
        // {"name": "Minimum Pay"}],
        // "rows": [
        //     ["1700", "6", "$1500", "$0.88"],
        //     ["1600", "5", "$1200", "$0.75"],
        //     ["1500", "4", "$1000", "$0.67"]
        // ],
        // "overbalance": "**Every mile over 1600 paid at the greater of $0.50 or their tenure rate"
    }
    const FNER = {
        "info": ["Home weekly for 34 hour reset", "$25 tarp pay", "Potential to run 2,000+ miles per week", "Runs East of Columbus, OH and North of NC"],
        "columns": [
            {"name": "Count"},
            {"name": "Loads"},
            {"name": "Min"},
            {"name": "Per Load"}],
        "columns2": [
            {"name": "Mileage"},
            {"name": "Miles"},
            {"name": "Min Amount"},
            {"name": "Per Mile"}],
        "rows": [
            ["Band 1", "4", "$1,000", "$250.00"],
            ["Band 2", "5", "$1,150", "$230.00"],
            ["Band 3", "6", "$1,300", "$216.67"]
        ],
        "rows2": [
            ["Band 1", "1,500", "$1,000", "$0.67"],
            ["Band 2", "1,600", "$1,100", "$0.69"],
            ["Band 3", "1,700", "$1,200", "$0.71"],
            ["Band 4", "1,800", "$1,300", "$0.72"],
            ["Band 5", "1,900", "$1,400", "$0.74 "],
            ["Band 6", "2,000", "$1,500", "$0.75 "]
        ],
        "overbalance": "**Every mile over 1600 paid at the greater of $0.40 or their tenure rate"
    }
    const EastCoastRegional = {
        "info": ["Home weekly for 34 hour reset", "Potential to run 2,000+ miles per week", "Potential to run everything East of the MS river"],
        "columns": [
            {"name": "Load Count"},
            {"name": "# of Loads"},
            {"name": "Min Amount"},
            {"name": "Per Load"}],
        "columns2": [
            {"name": "Mileage"},
            {"name": "Miles"},
            {"name": "Min Amount"},
            {"name": "Per Mile"}],
        "rows": [
            ["Band 1", "4", "$800", "200.00"],
            ["Band 2", "5", "$900", "180.00"],
            ["Band 3", "6", "$1,000", "166.67"]
        ],
        "rows2": [
            ["Band 1", "1,600", "$800", "$0.500"],
            ["Band 2", "1,800", "$900", "$0.500"],
            ["Band 3", "1,900", "$1,000", "$0.526"],
            ["Band 4", "2,000", "$1,100", "$0.550"],
            ["Band 5", "2,100", "$1,200", "$0.571 "]
        ],
        "overbalance": "Plus .40 CPM or current solo rate over mileage bands"
    }
    const FDDED = {
        "info": ["Home Weekly for 34 hour reset", "Dedicated loads for Fort Dodge", "Typical Routes: Chicago to Minneapolis Eastern IA back to Minneapolis"],
        // "columns": [
        // {"name": "Miles"},
        // {"name": "Loads"},
        // {"name": "Minimum Pay"}],
        // "rows": [
        //     ["1700", "6", "$1500", "$0.88"],
        //     ["1600", "5", "$1200", "$0.75"],
        //     ["1500", "4", "$1000", "$0.67"]
        // ],
        // "overbalance": "**Every mile over 1600 paid at the greater of $0.50 or their tenure rate"
    }
    const GPK = {
        "info": ["Only local route", "Slip seat trucks as needed", "Will have to work some weekends", "Western Express trucks stay on the yard while driver is not working", "Newest drivers will most likely run afternoon/evening shifts", "Must be able to back into and deliver in tight spaces/caves", "Delivers locally as well out of town in surrounding areas"],
        // "columns": [
        // {"name": "Miles"},
        // {"name": "Loads"},
        // {"name": "Minimum Pay"}],
        // "rows": [
        //     ["1700", "6", "$1500", "$0.88"],
        //     ["1600", "5", "$1200", "$0.75"],
        //     ["1500", "4", "$1000", "$0.67"]
        // ],
        // "overbalance": "**Every mile over 1600 paid at the greater of $0.50 or their tenure rate"
    }
    const PPV = {
        "info": ["6 Loads = $900 plus $75 for each additional load.", "***PPV has dedicated accounts that may differ from what is listed for the miles and load count please check with Driver Manager if you need more clarification."],
        "columns": [
        {"name": "Mileage"},
        {"name": "Miles"},
        {"name": "Min Amount"}],
        "rows": [
            ["Band 1", "1,600", "$800"],
            ["Band 2", "1,800", "$900"],
            ["Band 3", "1,900", "$1,000"],
            ["Band 4", "2,000", "$1,100"],
            ["Band 5", "2,100", "$1,200"]

            // Rework table for $550 increase per addtional load past 6
        ],
        "overbalance": "",
    }

    const WPO = {
        "info": ["14-16 days out/Home 2-3", "Potential to run 2,500+ miles per week", "Potential to run all 48 States"],
        // "columns": [
        // {"name": "Miles"},
        // {"name": "Loads"},
        // {"name": "Minimum Pay"}],
        // "rows": [
        //     ["1700", "6", "$1500", "$0.88"],
        //     ["1600", "5", "$1200", "$0.75"],
        //     ["1500", "4", "$1000", "$0.67"]
        // ],
        // "overbalance": "**Every mile over 1600 paid at the greater of $0.50 or their tenure rate"
    }

    useEffect(() => {
        findDivisionInfo();
        ApiService.sendEventCommand("Divison Info", "eventCheck", "User checked their division info");
    }, []);

    function findDivisionInfo() {
        switch (userStore.getState().driver?.division) {
            case "100":
                setDivInfo(EastCoastVan100);
                break;
            case "500":
                setDivInfo(FBOTR500);
                break;
            case "800":
                setDivInfo(WestCoastVan800);
                break;
            case "FNR":
                setDivInfo(FNER);
                setAdditionalInfo(true)
                break;
            case "NER":
                setDivInfo(EastCoastRegional);
                setAdditionalInfo(true)
                break;
            case "PPV":
                setDivInfo(PPV);
                setAdditionalInfo(true)
                break;
            case "VCT":
                setDivInfo(EastCoastRegional);
                setAdditionalInfo(true)
                break;
            case "VVA":
                setDivInfo(EastCoastRegional);
                setAdditionalInfo(true)
                break;
            default:
                setNoDiv(true);
                break;
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home" text=""></IonBackButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
            <IonItem lines="none"><div className="item-container"><h3><strong>Division Info</strong></h3></div></IonItem> 
            <ul>
                {divInfo.info?.map((item: string, index: number) => {
                    return <li key={index}><p>{item}</p></li>
                })}
            </ul>
            {additionalInfo ? <div>
            {userStore.getState().driver?.division !== "NER" && userStore.getState().driver?.division !== "FNR" ? <div><IonItem lines="none"><div className="item-container"><h3><strong>Minimum Pay</strong></h3>
            <div style={{ backgroundColor: "#ffffff" , borderRadius: "10px", textAlign: "center"}}>
                <div style={{ color: "#212121", display: "flex", justifyContent: "space-between", border: "2px solid #eee", padding: "8px", borderTopLeftRadius: "10px", borderTopRightRadius: "10px", borderBottom: "none"}}>
                    {divInfo.columns !== undefined ? divInfo.columns?.map(function(element: any) {
                                        return <p style={{ margin: 'auto'}}>{element.name}</p>   
                                }
                    ) : null}
               </div>
         
               {divInfo.rows !== undefined ?divInfo.rows?.map(function(element: any) { 
                                let dRow = 
                                    <div style={{ color: "#212121", display: "flex", justifyContent: "space-between", border: "2px solid #eee", borderBottom: "none"}}>
                                        {element.map(function(nElement: any) {
                                            let payInfo =
                                            <p style={{ margin: 'auto'}}>{nElement}</p>
                                            return payInfo;
                                        })}
                                    </div>
                                return dRow;
                            }
                ) : null}
                <p style={{ color: "#212121", border: "2px solid #eee", padding: '8px', margin: 0, borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px"}}>{divInfo.overbalance}</p>
            </div>
                </div></IonItem></div> : 
            // NER / FNR section
            <div><IonItem lines="none"><div className="item-container"><h3><strong>Minimum Pay</strong></h3>
                <div style={{ backgroundColor: "#ffffff" , borderRadius: "10px"}}>
                    <div style={{ color: "#212121", display: "flex", justifyContent: "space-between", border: "2px solid #eee", padding: "8px", borderTopLeftRadius: "10px", borderTopRightRadius: "10px", borderBottom: "none"}}>
                        {divInfo.columns !== undefined ? divInfo.columns?.map(function(element: any) {
                                            return <p style={{ margin: 'auto'}}>{element.name}</p>   
                                    }
                        ) : null}
                </div>
            
                {divInfo.rows !== undefined ?divInfo.rows?.map(function(element: any) { 
                                    let dRow = 
                                        <div style={{ color: "#212121", display: "flex", justifyContent: "space-between", border: "2px solid #eee", borderBottom: "none"}}>
                                            {element.map(function(nElement: any) {
                                                let payInfo =
                                                <p style={{ margin: 'auto'}}>{nElement}</p>
                                                return payInfo;
                                            })}
                                        </div>
                                    return dRow;
                                }
                    ) : null}
                    <div style={{ color: "#212121", display: "flex", justifyContent: "space-between", border: "2px solid #eee", padding: "8px", borderTopLeftRadius: "10px", borderTopRightRadius: "10px", borderBottom: "none"}}>
                        {divInfo.columns2 !== undefined ? divInfo.columns2?.map(function(element: any) {
                            return <p style={{ margin: 'auto'}}>{element.name}</p>   
                        }
                    ) : null}
                </div>
                    {divInfo.rows2 !== undefined ?divInfo.rows2?.map(function(element: any) {
                        let dRow = 
                            <div style={{ color: "#212121", display: "flex", justifyContent: "space-between", border: "2px solid #eee", borderBottom: "none"}}>
                                {element.map(function(nElement: any) {
                                    let payInfo =
                                    <p style={{ margin: 'auto'}}>{nElement}</p>
                                    return payInfo;
                                })}
                            </div>
                        return dRow;
                        }
                    ) : null}
                    <p style={{ color: "#212121", border: "2px solid #eee", padding: '8px', margin: 0, borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px"}}>{divInfo.overbalance}</p>
                    </div>
                    </div>
                </IonItem>
            </div>
            } </div> : null }
            </IonContent>
        </IonPage>
    )
}

export default DivisionInfo
